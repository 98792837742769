import { Cancel, CheckCircle } from "@mui/icons-material";
import { Backdrop, Box, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EBTTabs } from "../../components/Tabs";
import { fetchEBTHealth, fetchEBTIssuanceLogs, fetchEBTRedemptionLogs, fetchStateCodes } from "../../network/apiCalls";
import { setEBTStateCodes } from "../../store/dropdownSlice";
import { setEBTHealth, setIssuance, setRedemption } from "../../store/ebtSlice";
import { getEBTHealth, getEBTIssuance, getEBTLoadingStatus, getEBTRedemption, getEBTStateCode, getEBTTabIndex, getEnv, getFilteredIssuance, getFilteredRedemption } from "../../store/storeSelectors";
import { LoadingPage } from "../LoadingPage";

export const EBTIssuance = () => {
  const selectedEnv = useSelector(getEnv);
  const dispatch = useDispatch();
  const issuance = useSelector(getEBTIssuance)
  const redemption = useSelector(getEBTRedemption)
  const ebtHealth = useSelector(getEBTHealth);
  const showLoader = useSelector(getEBTLoadingStatus)
  const ebtTabIdx = useSelector(getEBTTabIndex)
  const filteredIssuance = useSelector(getFilteredIssuance)
  const filteredRedemption = useSelector(getFilteredRedemption)
  const selectedEBTStateCode = useSelector(getEBTStateCode);

  const { isLoading, isError, data, refetch, isFetching } = useQuery({
    queryKey: ["issuance"],
    queryFn: () => fetchEBTIssuanceLogs(selectedEnv, true, dispatch),
  });

  const EBTRedemptionQuery = useQuery({
    queryKey: ["redemption"],
    queryFn: () => fetchEBTRedemptionLogs(selectedEnv, true, dispatch),
  });

  const EBTHealthQuery = useQuery({
    queryKey: ["health"],
    queryFn: () => fetchEBTHealth(selectedEnv),
  });

  const StateCodeQuery = useQuery({
    queryKey: ["stateCodes"],
    queryFn: () => fetchStateCodes(selectedEnv)
  })

  const stateCodes = StateCodeQuery.data

  const redemptionData = EBTRedemptionQuery?.data;

  const EBTHealthData = EBTHealthQuery?.data;

  useEffect(() => {
    dispatch(setIssuance(data?.processIssuanceDetails));
    dispatch(setRedemption(redemptionData?.redemptionDetails));
    dispatch(setEBTStateCodes(stateCodes))
    dispatch(setEBTHealth(EBTHealthData));
  }, [EBTHealthData, data?.processIssuanceDetails, dispatch, redemptionData?.redemptionDetails, stateCodes])

  const showHealthStatus = (status: string) => {
    const healthStatus: any = {
      Active: <CheckCircle color="success" />,
      Stopped: <Cancel color="error" />,
      Running: <CheckCircle color="success" />,
    };
    return healthStatus[status]
  };

  return (
    <Box sx={{ flex: 1 }}>
      {
        showLoader && <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={showLoader}
        >
          <LoadingPage />
        </Backdrop>
      }
      <Typography variant="h6" component="h1" pb={2}>
        EBT Process
      </Typography>
      {
        ebtHealth?.length ? <Box>
          <Typography variant="h6" component="h1">
            EBT Health
          </Typography>
          <Table sx={{ width: 600, my: 2 }} >
            <TableHead sx={{ bgcolor: "#4A2E62" }}>
              <TableRow>
                <TableCell align='left' style={{ color: "white" }}>Service Name</TableCell>
                <TableCell align='left' style={{ color: "white" }}>Region</TableCell>
                <TableCell align='center' style={{ color: "white" }}>Health</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ebtHealth?.map((item: any, index: number) => (
                <TableRow key={index}>
                  <TableCell align='left'>{item.serviceName}</TableCell>
                  <TableCell align='left'>{item.regionName}</TableCell>
                  <TableCell align='center'><Tooltip title={item.health}>{showHealthStatus(item.health)}</Tooltip></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box> : null
      }
      {isLoading ? <LoadingPage /> :
        (issuance?.length || redemption?.length) && <EBTTabs issuance={filteredIssuance.length ? filteredIssuance :  issuance} isLoading={isLoading} redemption={filteredRedemption?.length ? filteredRedemption : selectedEBTStateCode ? [] : redemption} />}
    </Box>
  );
};
