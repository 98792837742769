import { ArrowForward } from "@mui/icons-material";
import {
  Box,
  Tooltip,
  Typography
} from "@mui/material";

import {
  DataGrid,
  GridColDef,
  GridToolbar,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { DailyProgressChart } from "../../components/DailyProgressChart";
import { ServiceStatus } from "../../components/ServiceStatus";
import {
  fetchInstance,
  fetchJobNameDetails,
  fetchWeeklyHealth,
} from "../../network/apiCalls";
import { getEnv } from "../../store/storeSelectors";

export const MonitoringJobInstancePage = () => {
  const selectedEnv = useSelector(getEnv);

  const { instanceId } = useParams();
  const { state } = useLocation();
  const { jobName, stateCode } = state;
  const showPartial = false;

  const { isLoading, isError, data, refetch, isFetching } = useQuery({
    queryKey: ["monitor", instanceId],
    queryFn: () => fetchInstance(selectedEnv, instanceId!),
  });

  const jobNameDetailsQuery = useQuery({
    queryKey: ["jobNameDetails", jobName],
    queryFn: () => fetchJobNameDetails(selectedEnv, jobName, stateCode),
  });

  const weeklyHealthQuery = useQuery({
    queryKey: ["weeklyHealth", selectedEnv],
    queryFn: () => fetchWeeklyHealth(selectedEnv, jobName, stateCode),
  });

  const batchJobDetailsByJobName =
    jobNameDetailsQuery?.data?.data?.batchJobDetailsByJobName;

  const batchJobFailureDetails =
    jobNameDetailsQuery?.data?.data?.batchJobFailureDetails;

  batchJobDetailsByJobName?.sort((a: any, b: any) => Date.parse(b.date) - Date.parse(a.date));

  const batchJobWeeklyHealthDetails = weeklyHealthQuery?.data?.data;

  const columns: GridColDef[] = [
    {
      field: "timeGenerated",
      headerName: "Time Generated [UTC]",
      width: 200,
      headerClassName: "super-app-theme--header",
      resizable: true,
      valueGetter: (params: GridValueGetterParams<any, string>) => {
        return params.value ? new Date(params.value).toUTCString() : null;
      },
    },
    {
      field: "type",
      width: 150,
      headerClassName: "super-app-theme--header",
      headerName: "Type",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "operationName",
      width: 150,
      headerClassName: "super-app-theme--header",
      headerName: "Operation",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "message",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerName: "Message",
      sortable: false,
      disableColumnMenu: true,
    },
  ];

  const jobFailureColomns: GridColDef[] = [
    {
      field: "timeGenerated",
      headerName: "Time Generated [UTC]",
      width: 200,
      headerClassName: "super-app-theme--header",
      resizable: true,
      valueGetter: (params: GridValueGetterParams<any, string>) => {
        return params.value ? new Date(params.value).toUTCString() : null;
      },
    },
    {
      field: "stateCode",
      width: 150,
      headerClassName: "super-app-theme--header",
      headerName: "State Code",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "exceptionType",
      minWidth: 200,
      headerClassName: "super-app-theme--header",
      headerName: "Exception Type",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "count",
      width: 150,
      headerClassName: "super-app-theme--header",
      headerName: "Count",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "message",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerName: "Message",
      sortable: false,
      disableColumnMenu: true,
    },
  ];

  const getMoreInfoURL = () => {
    switch (selectedEnv) {

      case "UAT":
        return `https://portal.azure.us/#@fnsmis.onmicrosoft.com/resource/subscriptions/bedf6009-b566-4a54-b559-b6eecdbf6b24/resourceGroups/WIC-UAT-BATCH-RG/providers/Microsoft.Logic/workflows/${stateCode}-UAT-LogicApp-${jobName}-01/logicApp`;
      case "SIT":
        return `https://portal.azure.us/#@fnsmis.onmicrosoft.com/resource/subscriptions/20b42d10-dbff-4e92-aed8-b808d5ee95a3/resourceGroups/WIC-SIT-BATCH-RG/providers/Microsoft.Logic/workflows/${stateCode}-SIT-LogicApp-${jobName}-01/logicApp`;
      case "PROD":
        return `https://portal.azure.us/#@fnsmis.onmicrosoft.com/resource/subscriptions/9fce676c-f0f8-4d93-a386-e3210a5b5809/resourceGroups/WIC-PROD-BATCH-RG/providers/Microsoft.Logic/workflows/${stateCode}-PROD-LogicApp-${jobName}-01/logicApp`;
      case "Production":
        return `https://portal.azure.us/#@fnsmis.onmicrosoft.com/resource/subscriptions/9fce676c-f0f8-4d93-a386-e3210a5b5809/resourceGroups/WIC-PROD-BATCH-RG/providers/Microsoft.Logic/workflows/${stateCode}-PROD-LogicApp-${jobName}-01/logicApp`;
      default:
        return ""
    }
  }

  return (
    <Box>
      <Typography variant="h6" component="h1" pb={2}>
        {`Selected State: ${stateCode},  Selected Env: ${selectedEnv}`}
      </Typography>
      <Typography variant="h6" component="h1" py={2}>
        Job name: {jobName}
      </Typography>
      <Typography variant="h6" component="h2" py={2}>
        Weekly Progress
      </Typography>
      <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center', }}>
        <DailyProgressChart
          data={batchJobDetailsByJobName}
          showPartial={showPartial} />
        <Box sx={{ margin: 'auto', borderWidth: "1px", borderStyle: 'solid', borderColor: '#ccc', borderRadius: 100, padding: "30px 35px" }}><Tooltip title="For more Info, click here!"><a style={{ color: 'inherit' }} href={getMoreInfoURL()} target="_blank" rel="noreferrer"><ArrowForward fontSize='large' color={"inherit"} /> </a></Tooltip></Box>
      </Box>
      <Typography variant="h6" component="h2" py={2}>
        Batch Job Service Status
      </Typography>
      <Box>
        {batchJobWeeklyHealthDetails?.length && (
          <ServiceStatus
            jobStatus={batchJobWeeklyHealthDetails}
            jobName={jobName}
          />
        )}
      </Box>
      <Typography variant="h6" component="h2" py={2}>
        Last Run Batch Job Details
      </Typography>
      <DataGrid
        density="compact"
        autoHeight
        rows={isLoading ? [] : data}
        loading={isLoading || isFetching}
        columns={columns}
        getRowId={(row) => row.id}
        disableRowSelectionOnClick
        sx={{
          fontSize: 12,
          "& .super-app-theme--header": {
            backgroundColor: "#4A2E62",
            color: "white",
          },
        }}
        pagination
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 15,
            },
          },
        }}
        pageSizeOptions={[5, 10, 15]}
      />
      {batchJobFailureDetails?.length ? (
        <Typography variant="h6" component="h2" py={2}>
          Weekly Exception Details
        </Typography>
      ) : null}
      {batchJobFailureDetails?.length ? (
        <DataGrid
          density="compact"
          autoHeight
          rows={batchJobFailureDetails}
          loading={isLoading || isFetching}
          columns={jobFailureColomns}
          getRowId={(row) => {
            return row.message;
          }}
          disableRowSelectionOnClick
          sx={{
            fontSize: 12,
            "& .super-app-theme--header": {
              backgroundColor: "#4A2E62",
              color: "white",
            },
          }}
          pagination
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 15,
              },
            },
          }}
          pageSizeOptions={[5, 10, 15, 20, 50]}
        />
      ) : (
        <Box
          sx={{
            flex: 1,
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography variant="h6" component="h6" py={2}>
            -- No Exceptions --
          </Typography>
        </Box>
      )}
    </Box>
  );
};
