import { configureStore } from "@reduxjs/toolkit";
import DropwDownReducer from './dropdownSlice';
import BatchJobReducer from './batchJobsSlice';
import EBTReducer from './ebtSlice';
import MyWicReducer from './myWicSlice';
import CRMReducer  from './crmSlice';

const Store = configureStore({
    reducer: {
        dropdowns: DropwDownReducer,
        batchJobs: BatchJobReducer,
        ebt: EBTReducer,
        myWic: MyWicReducer,
        crm: CRMReducer
    }
})

export default Store;

export type AppState = ReturnType<typeof Store.getState>
