interface RoutesType {
    [key: string]: {
        path: string,
        title: string
    }
}

export const ROUTES: RoutesType = {
    home: {
        path: '/',
        title: 'Home',
    },
    // managementConfigurations: {
    //     path: '/management/configurations',
    //     title: 'Configurations'
    // },
    managementFeatures: {
        path: '/management/features',
        title: 'Features'
    },
    monitoringOverview: {
        path: '/monitoring',
        title: 'Dashboard'
    },
    monitoringJobs: {
        path: '/monitoring/jobs',
        title: 'Batch Jobs'
    },
    monitoringJobInstance: {
        path: '/monitoring/jobs/:instanceId',
        title: 'Batch Jobs'
    },
    batchJobs: {
        path: '/monitoring/batchJob',
        title: 'Batch Jobs'
    },
    monitoringCrm: {
        path: '/monitoring/crm/plugin_exceptions',
        title: 'CRM'
    },
    monitoringCrmAvail: {
        path: '/monitoring/crm/availability',
        title: 'Availability'
    },
    monitoringCrmPlugins: {
        path: '/monitoring/crm/plugin_exceptions',
        title: 'PluginExceptions'
    },
    monitoringClientSideExceptions: {
        path: "/monitoring/crm/clientside_exceptions",
        title: "ClientSideExceptions"
    },
    monitoringEbt: {
        path: '/monitoring/ebt',
        title: 'EBT'
    },
    monitoringSms: {
        path: '/monitoring/sms',
        title: 'SMS'
    },
    monitoringMyWic: {
        path: '/monitoring/mywic',
        title: 'myWIC'
    },
    monitoringMyWicExceptions: {
        path: '/monitoring/mywic/exceptionDetails',
        title: 'myWIC'
    },
    securityAccessRequests: {
        path: '/security/accessRequests',
        title: 'Access Requests'
    },
    error: {
        path: '/error',
        title: 'Error',
    },
};

export const getPathTitle = (path: string) => {
    var routeKeys = Object.keys(ROUTES)
    var routes = routeKeys.filter(x => ROUTES[x].path === path)
    console.log(path)
    if (routes.length > 0) {
        return ROUTES[routes[0]].title
    }
}