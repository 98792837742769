import { Box, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useParams, useLocation } from 'react-router-dom';
import { fetchMyWICExceptions } from '../../network/apiCalls';
import { LoadingPage } from '../LoadingPage';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { EBTIssuanceChart } from '../../components/EBTIssuanceChart';
import { useSelector } from 'react-redux';
import { getEnv } from '../../store/storeSelectors';

const jobFailureColomns: GridColDef[] = [

  {
    field: "operationId",
    width: 150,
    headerClassName: "super-app-theme--header",
    headerName: "Operation Id",
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: "operationName",
    minWidth: 200,
    headerClassName: "super-app-theme--header",
    headerName: "Operation Name",
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: "timeGenerated",
    headerName: "Time Generated [UTC]",
    width: 200,
    headerClassName: "super-app-theme--header",
    resizable: true,
    valueGetter: (params: GridValueGetterParams<any, string>) => {
      return params.value ? new Date(params.value).toUTCString() : null;
    },
  },
  {
    field: "appVersion",
    width: 120,
    headerClassName: "super-app-theme--header",
    headerName: "App Version",
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: "exceptionType",
    minWidth: 200,
    headerClassName: "super-app-theme--header",
    headerName: "Exception Type",
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: "method",
    minWidth: 200,
    headerClassName: "super-app-theme--header",
    headerName: "Method",
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: "outerMessage",
    flex: 1,
    headerClassName: "super-app-theme--header",
    headerName: "Message",
    sortable: false,
    disableColumnMenu: true,
  },
];


export const MonitorExceptionsPage = () => {

    const { state } = useLocation();
    const { operationName, startDate, endDate } = state;
    const [isLoading, setIsLoading] = useState(true);
    const [exceptions, setExceptions] = useState({exceptionDetails: [], failCount: []});
    const selectedEnv = useSelector(getEnv);


    useEffect(()=> {  
        axios
          .get("/myWic/SuccessFailCountByOperationName", { params: { Environment: selectedEnv, OperationName: operationName, startDate: startDate, endDate: endDate } })
          .then((response:any) => {
            setIsLoading(false);
            setExceptions(response.data)
        }).catch(e => setIsLoading(false))
    },[endDate, operationName, startDate, selectedEnv])

    return (
        <Box>
            <Typography variant="h6" component="h1" py={2}>
                Operation name: {operationName}
            </Typography>

            {
              isLoading ? <LoadingPage /> : exceptions?.exceptionDetails?.length ? 
                <Box>
                  <EBTIssuanceChart chart={exceptions} updateTable={setExceptions} />
                  <Typography variant="h6" component="h1" py={2}>
                    Exception Count
                  </Typography>
                  <Table sx={{width: 600, my: 2}} >
                    <TableHead sx={{ bgcolor: "#4A2E62" }}>
                        <TableRow>
                        <TableCell align='left' style={{ color: "white" }}>Exception Type</TableCell>
                        <TableCell align='center' style={{ color: "white" }}>Count</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {exceptions?.failCount.map(( item: any, index: number) => (
                        <TableRow key={index}>
                            <TableCell align='left'>{item.exceptionType}</TableCell>
                            <TableCell align='center'>{item.count}</TableCell>
                        </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  <Typography variant="h6" component="h1" py={2}>
                    Exception Details
                  </Typography>
                  <DataGrid 
                    density="compact"
                    autoHeight
                    rows={exceptions?.exceptionDetails}
                    loading={isLoading}
                    columns={jobFailureColomns}
                    getRowId={(row) => {
                      return row.operationId
                    }}
                    disableRowSelectionOnClick
                    sx={{
                      fontSize: 12,
                      "& .super-app-theme--header": {
                        backgroundColor: "#4A2E62",
                        color: "white",
                      },
                    }}
                    pagination
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 10,
                        },
                      },
                    }}
                    pageSizeOptions={[5, 10, 15, 20, 50]}
                  />
                </Box>
                : <Box
                sx={{
                  flex: 1,
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
              <Typography variant="h6" component="h6" py={2}>
                -- No Exceptions --
              </Typography>
            </Box>
            }
           
        </Box>
    )
}