import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { theme } from './themes';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';

import { AppRoutes } from './AppRoutes';
import { ErrorPage, LoadingPage } from './pages';
import { Provider } from 'react-redux';
import Store from './store/store';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

export const App = () => {

  return (
    <ThemeProvider theme={theme}>
      <Provider store={Store}>
      <CssBaseline />
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} loadingComponent={LoadingPage} errorComponent={ErrorPage}>
        <QueryClientProvider client={queryClient}>
          <AppRoutes />
        </QueryClientProvider>
      </MsalAuthenticationTemplate>
      </Provider>
    </ThemeProvider>
  );

}
