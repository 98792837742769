import { Box, Typography, colors } from "@mui/material";
import React from "react";
import { Cell, Pie, PieChart, Sector, Tooltip } from "recharts"

interface chartProps {
    pieData: any,
    onClick?: (item: any) => void
}

const ExMsgChart: React.FC<chartProps> = ({ pieData, onClick }) => {


    const RenderTooltip = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            return <Box sx={{
                backgroundColor: "white",
                borderColor: "primary.main",
                borderRadius: 2,
                borderStyle: "solid",
                borderWidth: 1,
                overflowWrap: 'break-word',
                wordWrap: 'break-word',
                p: 2
            }}>
                <Typography color={'red'}>{payload[0]?.payload?.pluginName}</Typography>
                <Typography sx={{ display: 'flex', }}>{`${payload[0].name} : ${payload[0].value}`}</Typography>
            </Box>
        }
        return null;
    }

    const onPointClick = (item: any) => {
        onClick && onClick(item);
    }

    const renderActiveShape = (props: any) => {
        const RADIAN = Math.PI / 100;
        const {
          cx,
          cy,
          innerRadius,
          outerRadius,
          startAngle,
          endAngle,
          midAngle
        } = props;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius) * cos;
        const sy = cy + (outerRadius) * sin;
        return (
          <Sector
            cx={sx}
            cy={sy}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            startAngle={startAngle}
            endAngle={endAngle}
            fill="red"
          />
        );
      };

    const COLORS = [
        '#0088FE', '#00C49F', '#FFBB28', '#FF8042',
        '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0',
        '#1976d2', '#1565c0', '#0d47a1', '#82b1ff', 
        '#448aff', '#2979ff', '#2962ff'
      ];

    return <PieChart width={800} height={600}>

        <Pie
            nameKey={'name'}
            dataKey={'value'}
            data={pieData}
            cx={'30%'}
            cy={'30%'}
            outerRadius={100}
            legendType="rect"
            // activeShape={renderActiveShape}
            label>
            {
                pieData.map((entry: any, index: number) => (
                    <Cell key={index}
                        fill={COLORS[index]}
                        onClick={() => onPointClick(entry)}
                    />
                ))
            }

        </Pie>
        <Tooltip content={<RenderTooltip />} />
    </PieChart>
}

export const ExceptionMessageChart = React.memo(ExMsgChart);