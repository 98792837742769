import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  services: [],
  health: [],
  isLoading: false
}

const myWicSlice = createSlice({
    name: 'mywic',
    initialState,
    reducers: {
        setMyWicService: (state, action)=> {
            state.services = action.payload
        },
        setMyWicHealth: (state, action)=> {
            state.health = action.payload
        },
        setMyWicLoader: (state, action)=> {
            state.isLoading = action.payload
        }
    }
})

export const { setMyWicService, setMyWicHealth, setMyWicLoader} = myWicSlice.actions;

export default myWicSlice.reducer;
