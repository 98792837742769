import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { RootLayout } from "./layouts/RootLayout";
import {
  HomePage,
  ErrorPage,
  MonitoringJobsPage,
  MonitoringJobInstancePage,
} from "./pages";

import { ROUTES } from "./config/Routes";
import { useAccount, useMsal } from "@azure/msal-react";
import axios from "axios";
import { EBTIssuance } from "./pages/EBT/EBTIssuance";
import { MyWICMonitoringPage } from "./pages/myWIC/MonitoringPage";
import { MonitorExceptionsPage } from "./pages/myWIC/MonitorExceptionsPage";
import { Configurations } from "./pages/Management/Configurations";
import { useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { fetchFeatureFlags, fetchStateCodes } from "./network/apiCalls";
import { setAllStateCodes } from "./store/dropdownSlice";
import { setCRMFlags, setCRMEnvDetails, setCRMTableLoader, setUnavailableFlags } from "./store/crmSlice";
import { BatchJobs } from "./pages/Monitoring/BatchJobs";
import { Availability } from "./pages/CRM/Availability";
import { PluginExceptions } from "./pages/CRM/PluginExceptions";
import { ClientSideExceptions } from "./pages/CRM/ClientSideExceptions";

export const routes = [
  {
    element: <RootLayout />,
    children: [
      {
        path: ROUTES.home.path,
        element: <HomePage />,
      },
      {
        children: [
          {
            path: ROUTES.managementFeatures.path,
            element: <Configurations />,
          },
        ],
      },
      {
        children: [
          {
            path: ROUTES.monitoringOverview.path,
            element: <span>{ROUTES.monitoringOverview.title}</span>,
          },
          {
            path: ROUTES.monitoringJobs.path,
            element: <MonitoringJobsPage />,
          },
          {
            path: ROUTES.batchJobs.path,
            element: <BatchJobs />,
          },
          {
            path: ROUTES.monitoringJobInstance.path,
            element: <MonitoringJobInstancePage />,
          },
          {
            path: ROUTES.monitoringCrm.path,
            element: <PluginExceptions />
          },
          {
            path: ROUTES.monitoringCrmPlugins.path,
            element: <PluginExceptions />
          },
          {
            path: ROUTES.monitoringClientSideExceptions.path,
            element: <ClientSideExceptions />
          },
          {
            path:  ROUTES.monitoringCrmAvail.path,
            element: <Availability />
          },
          {
            path: ROUTES.monitoringEbt.path,
            element: <EBTIssuance />,
          },
          {
            path: ROUTES.monitoringSms.path,
            element: <span>{ROUTES.monitoringSms.title}</span>,
          },
          {
            path: ROUTES.monitoringMyWic.path,
            element: <MyWICMonitoringPage />
          },
          {
            path: ROUTES.monitoringMyWicExceptions.path,
            element: <MonitorExceptionsPage />
          }
        ],
      },
      {
        children: [
          {
            path: ROUTES.securityAccessRequests.path,
            element: <span>{ROUTES.securityAccessRequests.title}</span>,
          },
        ],
      },
      {
        path: "*",
        element: <span>Not Found</span>,
      },
    ],
    errorElement: <ErrorPage />,
  },
];

export const AppRoutes = () => {
  const router = createBrowserRouter(routes);

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const dispatch = useDispatch();

  const StateCodeQuery = useQuery({
    queryKey: ["stateCodes"],
    queryFn: () => fetchStateCodes('PROD')
  })

  const stateCodes = StateCodeQuery.data

  dispatch(setAllStateCodes(stateCodes));

  const { isLoading, isError, data } = useQuery({
    queryKey: ["stateCode"],
    queryFn: () => fetchFeatureFlags('TX', false ,null),
  });
  dispatch(setCRMFlags(data?.result));
  dispatch(setCRMEnvDetails(data?.details));
  dispatch(setCRMTableLoader(isLoading))
  dispatch(setUnavailableFlags(data?.featureFlagNotPresentInLowerInstance))


  axios.interceptors.request.use(async (config) => {
    var response = await instance.acquireTokenSilent({
      account: account || undefined,
      scopes: [
        "openid",
        "profile",
        "offline_access",
        "5abb7891-f663-40f6-9ccc-ceb76d1728ce/.default",
      ],
    });
    config.headers["Authorization"] = `Bearer ${response.accessToken}`;

    return config;
  });

  return <RouterProvider router={router} />;
};
