import { PlayArrow } from '@mui/icons-material';
import { Backdrop, Box, Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Calendar } from '../../components/Calendar';
import { CrmExceptionsChart } from '../../components/CrmExceptionsChart';
import { PluginExceptionTabs } from '../../components/PluginExceptionTabs';
import { fetchCrmPluginExceptions } from '../../network/apiCalls';
import { setCRMFromDate, setCRMToDate, setFilteredPluginExceptions, setPluginExceptionLoader, setPluginExceptions, setPluginExceptionStateCode } from '../../store/crmSlice';
import { setOrganisationId } from '../../store/dropdownSlice';
import {
    getAllStateCodes,
    getEnv, getExByDates,
    getExByMessages,
    getExByPrograms, getFilteredPluginExceptions,
    getPluginExceptionLoadingStatus,
    getPluginExceptions,
    getPluginExceptionStateCode,
    getStateCodeOrgId,
    gettExByPlugins
} from '../../store/storeSelectors';
import {
    getExCountByDate, getExCountByProgram, getExMessage,
    getPluginCountByDate, getPluginCountByDatePieChart
} from '../../utils/CRM/PluginExceptions';
import { LoadingPage } from '../LoadingPage';

export const PluginExceptions = () => {
    const [fromDate, setFromDate] = useState<Dayjs | null>(dayjs().startOf('date'))
    const [toDate, setToDate] = useState<Dayjs | null>(dayjs().startOf('date').add(1, 'hour'));
    const [isManagedException, setIsManagedException] = useState("false");

    const selectedPEStateCode = useSelector(getPluginExceptionStateCode);
    const selectedEnv = useSelector(getEnv);
    const organizationId = useSelector(getStateCodeOrgId);
    const showLoader = useSelector(getPluginExceptionLoadingStatus);
    const pluginExceptions = useSelector(getPluginExceptions);
    const programExceptions = useSelector(getExByPrograms);
    const pluginByDateExceptions = useSelector(gettExByPlugins);
    const dateExceptions = useSelector(getExByDates);
    const messageExceptions = useSelector(getExByMessages);
    const filteredCrmExceptions = useSelector(getFilteredPluginExceptions);
    const allStateCodes: any = useSelector(getAllStateCodes);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setPluginExceptions([]));
        dispatch(setFilteredPluginExceptions([]));
        dispatch(setPluginExceptionStateCode(''));
        dispatch(setOrganisationId(''));
    },[dispatch])
    
    const fetchPluginExceptions = async () => {
        dispatch(setPluginExceptionLoader(true));
        const exceptionsResult = await fetchCrmPluginExceptions(selectedEnv, organizationId, fromDate?.toISOString()!, toDate?.toISOString()!, true, isManagedException);
        dispatch(setPluginExceptions(exceptionsResult));
        dispatch(setCRMFromDate(fromDate?.toISOString()));
        dispatch(setCRMToDate(toDate?.toISOString()));
        exceptionsResult && getExCountByProgram(exceptionsResult, allStateCodes);
        exceptionsResult && getExCountByDate(exceptionsResult);
        exceptionsResult && getExMessage(exceptionsResult);
        if (dayjs(toDate).diff(dayjs(fromDate), 'days') === 0) {
            exceptionsResult && getPluginCountByDatePieChart(exceptionsResult);
        } else {
            exceptionsResult && getPluginCountByDate(exceptionsResult);
        }
        dispatch(setFilteredPluginExceptions(exceptionsResult));

    }

    const handleManagedExceptions = (event: SelectChangeEvent) => {
        setIsManagedException(event.target.value)
    }

    return (

        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {
                showLoader && <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={showLoader}
                >
                    <LoadingPage />
                </Backdrop>
            }

            <Typography variant="h6" component="h1" pb={2}>
                {`Plugin Exceptions for ${selectedPEStateCode.length ? selectedPEStateCode + ' program' : 'all programs'}`}
            </Typography>
            <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                <Calendar label='From' value={fromDate} onChange={setFromDate} maxDate={dayjs()} minDate={dayjs(toDate).subtract(30, 'days')} />
                <span style={{ padding: '5pt' }} />
                <Calendar label='To' value={toDate} onChange={setToDate} maxDate={dayjs()} />
                <FormControl sx={{mx: 2, minWidth: 200}}>
                    <InputLabel id="demo-simple-select-label">Managed Exceptions</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={isManagedException}
                        label="Managed Exceptions"
                        onChange={handleManagedExceptions}
                    >
                        <MenuItem value={"true"}>Yes</MenuItem>
                        <MenuItem value={"false"}>No</MenuItem>
                    </Select>
                </FormControl>
                <Button variant="contained" startIcon={<PlayArrow />}
                    onClick={fetchPluginExceptions}
                    disabled={!allStateCodes}
                >
                    Run
                </Button>
            </Box>
           

            <Box sx={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
               {/* Exception count by Program */}
                {!selectedPEStateCode && pluginExceptions.length ?
                    <CrmExceptionsChart data={programExceptions} label={'Exception count by Program'} xAxisKey='state' dataKey='count' />
                    : null}

                {/* Exception count by Date */}
                { pluginExceptions.length ? <CrmExceptionsChart data={dateExceptions} label={'Exception count by Date'} xAxisKey='date' dataKey='count' />
                :  null
                 }
            </Box>

            <Box sx={{ mt: 2 }}>
                {pluginExceptions?.length ? <PluginExceptionTabs pluginCount={pluginByDateExceptions} exMessage={messageExceptions} plExceptionResult={filteredCrmExceptions?.length ? filteredCrmExceptions : (!filteredCrmExceptions?.length && selectedPEStateCode) ? [] : pluginExceptions} /> : null}
            </Box>
        </Box>
    )
}