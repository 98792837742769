/* eslint-disable eqeqeq */
import { Block, Cancel, CheckCircle, DoDisturbOnOutlined } from "@mui/icons-material";
import { Box, Chip, FormControl, Input, InputLabel, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, styled } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCRMEnvDetails, getCRMFlags, getCRMLoadingStatus, getCRMTableLoadingStatus, getEnv, getUnavailableFlags } from "../../store/storeSelectors";
import { LoadingPage } from "../LoadingPage";
import { useQuery } from "@tanstack/react-query";
import { fetchStateCodes } from "../../network/apiCalls";
import { setFeatFlagStateCodes } from "../../store/crmSlice";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
    },
  }));

const showStatus = (value: string) => {
  // eslint-disable-next-line eqeqeq
  if(value.toLowerCase() == 'true' || value.toLowerCase() == 'yes' ){
    return <CheckCircle color="success" />
  // eslint-disable-next-line eqeqeq
  }else return <Cancel color="error" /> 
}

export const Configurations = () => {
    const crmFlags: any[] = useSelector(getCRMFlags);
    const crmEnvDetails: any = useSelector(getCRMEnvDetails);

    const [search, setSearch] = useState<string>('');
    const showLoader = useSelector(getCRMLoadingStatus)
    const isFFLoading = useSelector(getCRMTableLoadingStatus)
    const lowerInstanceFlags: any = useSelector(getUnavailableFlags)

    const enabledFlags = crmEnvDetails && crmEnvDetails['True'];
    const disabledFlags = crmEnvDetails && crmEnvDetails['False'];
    const notsetFlags = crmEnvDetails && crmEnvDetails['NotSet'];
    const notDeployedFlags = crmEnvDetails && crmEnvDetails['Not Deployed / Deactivated'];
    const stateNames = enabledFlags && Object.keys(enabledFlags);
    const dispatch = useDispatch();

    const { data } = useQuery({
      queryKey: ["stateCodes"],
      queryFn: () => fetchStateCodes('PROD')
  })

  useEffect(()=> {
    dispatch(setFeatFlagStateCodes(data));
  }, [dispatch, data])


    return (
      <Box>
      <Typography variant="h6" component="h1" pb={2}>
        Feature Flags
      </Typography>
      {
        isFFLoading ? <LoadingPage /> :
      <Box>
       <Box>
        <Typography variant="h6" component="h1">
          Total Feature Flags Count
        </Typography>
        <Table sx={{width: '60%', my: 2}} >
          <TableHead sx={{ bgcolor: "#4A2E62" }}>
            <TableRow>
              <TableCell align='left' style={{ color: "white" }}>State</TableCell>
              <TableCell align='center' style={{ color: "white" }}>Enabled</TableCell>
              <TableCell align='center' style={{ color: "white" }}>Disabled</TableCell>
              <TableCell align='center' style={{ color: "white" }}>NotSet</TableCell>
              <TableCell align='center' style={{ color: "white" }}>Total</TableCell>
              <TableCell style={{ color: "white" }}>Not Deployed / Deactivated</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              enabledFlags ? Object.keys(enabledFlags).map((state: string) => {
                return <TableRow>
                  <TableCell align='left'>{showLoader ? <Skeleton /> : state}</TableCell>
                  <TableCell align='center'>{showLoader ? <Skeleton /> : enabledFlags[state]}</TableCell>
                  <TableCell align='center'>{showLoader ? <Skeleton /> : disabledFlags[state]}</TableCell>
                  <TableCell align='center'>{showLoader ? <Skeleton /> : notsetFlags[state]}</TableCell>
                  <TableCell align='center'>{showLoader ? <Skeleton /> : enabledFlags[state] + disabledFlags[state] + notsetFlags[state]}</TableCell>
                  <TableCell >{showLoader ? <Skeleton /> : notDeployedFlags[state]}</TableCell>
                </TableRow>
              }) : <TableRow> 
                    <TableCell colSpan={6} align="center"> -- No Data -- </TableCell> 
                  </TableRow>
            }
          </TableBody>
        </Table>
        </Box>
      <Typography variant="h6" component="h1" py={2} sx={{flex: 0.5}}>
        Enabled/Disabled Feature Flags
      </Typography>
      
      <Box sx={{ border: "1px solid #ccc", width: '80%', minHeight: '40px' }}>
        <Box sx={{display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center'}}>
        <FormControl sx={{ width: '25ch' }} variant="standard">
        <InputLabel htmlFor="standard-adornment-password">Search</InputLabel>
          <Input id="standard-basic" 
            type="text" 
            onChange={(e : ChangeEvent<HTMLInputElement>) => {
              setSearch(e.target.value)           
            }}
            value={search}
            margin='dense' />
        </FormControl>
          <span style={{display: 'flex', flex: 0.9, justifyContent: 'end'}}>
            <Chip icon={<CheckCircle color="success"/>} label="Enabled" />
            <Chip icon={<Cancel color="error" />} label="Disabled" sx={{mx:1}}/>
            <Chip icon={<DoDisturbOnOutlined color="warning" />} label="Not Set" sx={{mr:1}}/>
            <Chip icon={<Block color="disabled" />} label="Not Deployed / Deactivated"/>
          </span>
        </Box>
      </Box>
        <TableContainer component={Paper} sx={{width: '80%'}}>  
          <Table>
            <TableHead  sx={{ bgcolor: "#4A2E62" }}>
              <TableRow>
                <TableCell sx={{ color: "white" }}>Feature Flag</TableCell>
                {
                  crmFlags &&  stateNames?.map((item: any, index: number) => <TableCell key={index} align="center" sx={{ color: "white" }}>{showLoader ? <Skeleton /> : item}</TableCell>)
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {crmFlags ? Object.keys(crmFlags)?.filter((row: any) => {
                return !search.length || row
                .toString()
                .toLowerCase()
                .includes(search.toString().toLowerCase()) 
              }).map((flag: any, index: number) => 
                <StyledTableRow key={index}>
                 <TableCell>{lowerInstanceFlags.includes(flag)? <mark>{flag}</mark>: flag}</TableCell> 
                  {stateNames.map((elem: any) => { 
                    const value = crmFlags[flag].find((item: any) => item.key === elem)?.value;
                    return  <TableCell align="center">{showLoader ? <Skeleton /> : value ? showStatus(value) : value === null ? <DoDisturbOnOutlined color="warning" /> : <Block  color="disabled" />}</TableCell>
                  }) 
                  } 
                </StyledTableRow>
              ) : <TableRow> 
                    <TableCell align="center"> -- No Data -- </TableCell> 
                  </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
        </Box> 
      }
      </Box>
    );

}