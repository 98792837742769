import { FilterAlt, UnfoldMore } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import logo from "../assets/images/logo.svg";
import { fetchEBTHealth, fetchEBTIssuanceLogs, fetchEBTRedemptionLogs, fetchFeatureFlags, fetchGraph, fetchLogs, fetchMyWICHealth, fetchMyWICMonitoring, fetchStateCodes } from "../network/apiCalls";
import { setBatchJobs, setFilteredBatchJobs, setFilteredGraphData, setGraphData, setLoader } from "../store/batchJobsSlice";
import { setCRMClientSideExceptions, setCRMEnvDetails, setCRMFlags, setCRMLoader, setCRMStateCode, setClentSideExceptionStateCode, setClientSideExceptionsByProgram, setClientSideExceptionsCountByDate, setExByDates, setExByMessages, setExByPlugins, setExByPrograms, setExMessagesByPluginName, setFilteredClientSideExceptions, setFilteredPluginExceptions, setPlExceptionStateCodesLoader, setPluginExceptionStateCode, setPluginExceptions, setUnavailableFlags } from "../store/crmSlice";
import {
  clearSelectedStateCodes,
  setAllStateCodes,
  setCheckedState,
  setEnv,
  setOrganisationId,
  setOrganisationName,
  setSelectedStateCodes,
  setStateCodes
} from "../store/dropdownSlice";
import { setEBTHealth, setEBTLoader, setEBTStateCode, setFilteredIssuance, setFilteredRedemption, setIssuance, setRedemption } from "../store/ebtSlice";
import { setMyWicHealth, setMyWicLoader, setMyWicService } from "../store/myWicSlice";
import {
  getAllStateCodes,
  getBatchJobs,
  getCRMFFStateCode,
  getCheckedState,
  getClientSideExceptionStateCode,
  getClientSideExceptions,
  getCrmFromDate,
  getCrmToDate,
  getEBTIssuance,
  getEBTRedemption,
  getEBTStateCode,
  getEBTStateCodes,
  getEBTTabIndex,
  getEnv,
  getFeatFlagStateCodes,
  getGraphData,
  getPluginExceptionStateCode,
  getPluginExceptionStateCodesLoadingStatus,
  getPluginExceptions,
  getSelectedStateCodes,
  getStateCodes
} from "../store/storeSelectors";
import { getClientSideExByPrgram, getClientSideExCountByDate, getExCountByDate, getExCountByProgram, getExMessage, getPluginCountByDate, getPluginCountByDatePieChart } from "../utils/CRM/PluginExceptions";

type HeaderProps = {
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
};

const envs: Array<string> = ["SIT", "UAT", "Production"];

export const Header = ({ mobileOpen, handleDrawerToggle }: HeaderProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isEBTFilterOpen, setIsEBTFilterOpen] = useState(false);
  const [isCrmFFOpen, setIsCrmFFOpen] = useState(false);
  const [isCrmOpen, setIsCrmOpen] = useState(false);


  const location = useLocation();
  const [selectedIndex, setSelectedIndex] = useState(2);

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>();
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>();
  const [anchorEl3, setAnchorEl3] = useState<null | HTMLElement>();
  const [anchorEl4, setAnchorEl4] = useState<null | HTMLElement>();
  const [anchorEl5, setAnchorEl5] = useState<null | HTMLElement>();


  const [selectedStates, setSelectedStates] = useState<string[]>([]);

  const stateCodes: string[] = useSelector(getStateCodes);
  const checkedState = useSelector(getCheckedState);
  const stateCodeLength = stateCodes?.length;
  const selectedEnv = useSelector(getEnv);
  const selectedStateCodes = useSelector(getSelectedStateCodes);
  const ebtStateCodes: string[] = useSelector(getEBTStateCodes);
  const selectedEBTStateCode = useSelector(getEBTStateCode);
  const ebtTabIdx = useSelector(getEBTTabIndex);
  const allStateCodes: any = useSelector(getAllStateCodes);
  const selectedCRMStateCode = useSelector(getCRMFFStateCode); 
  const batchJobs = useSelector(getBatchJobs);
  const barGraphData = useSelector(getGraphData);
  const issuance = useSelector(getEBTIssuance)
  const redemption = useSelector(getEBTRedemption)
  const selectedPEStateCode = useSelector(getPluginExceptionStateCode);
  const selectedCEStateCode = useSelector(getClientSideExceptionStateCode);

  const crmPluginExceptions: any = useSelector(getPluginExceptions);
  const crmClientSideExceptions: any = useSelector(getClientSideExceptions);

  const crmFeatFlagStateCodes : any = useSelector(getFeatFlagStateCodes);
  const crmPluginExceptionsStateCodeLoading = useSelector(getPluginExceptionStateCodesLoadingStatus);
  

  const fromDate = useSelector(getCrmFromDate);
  const toDate = useSelector(getCrmToDate);


  useEffect(() => {
    if (!selectedStateCodes.length) {
      dispatch(setCheckedState(new Array(stateCodeLength).fill(false)));
    }
  }, [dispatch, selectedStateCodes.length, stateCodeLength, ebtTabIdx, allStateCodes, crmPluginExceptionsStateCodeLoading]);

  const handleFilter = () => {
    setIsFilterOpen(true);
  };

  const handleEBTFilter = () => {
    setIsEBTFilterOpen(true)
  }

  const handleCloseEBTFilter = () => {
    setIsEBTFilterOpen(false)
    setAnchorEl3(null);
  }

  const handleCloseFilter = () => {
    setIsFilterOpen(false);
    setAnchorEl2(null);
  };

  const handleClose = () => {
    setIsOpen(false);
    setAnchorEl(null);
  };

  const handleCRMFilter = () => {
    setIsCrmFFOpen(true);
  }

  const handleCloseCRMFilter = () => {
    setIsCrmFFOpen(false);
    setAnchorEl4(null);
  }

  const handleCRMPlugins = () => {
    setIsCrmOpen(true);
  }

  const handleCloseCRM = () => {
    setIsCrmOpen(false);
    setAnchorEl5(null);
  }

  const handleMenuItemClick = async (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    setIsOpen(false);
    setSelectedIndex(index);
    dispatch(setEnv(envs[index]));
    switch (location.pathname) {
      case "/monitoring/jobs":
        handleBatchJobsOperation(index);
        break;
      case "/monitoring/ebt":
        handleEBTOperation(index);
        break;
      case "/monitoring/mywic":
        handleMyWicOperation(index);
        break;
      case "/management/features":
        handleFeatureFlags(index);
        break;
      case "/monitoring/crm/plugin_exceptions":
        handleCRMPluginExceptions(index, location.pathname);
        break;
      case "/monitoring/crm/clientside_exceptions":
        handleCRMPluginExceptions(index, location.pathname);
        break;
      default:
        break;
    }
  };

  const handleBatchJobsOperation = async (index: number) => {
    setSelectedStates([]);
    dispatch(clearSelectedStateCodes());
    dispatch(setLoader(true))
    
    const data = await fetchLogs(envs[index].toString(), true, dispatch);
    const graphData = await fetchGraph(envs[index].toString(), false , null);

    let progressArr: any = [];

    graphData?.map((item: any) =>
      progressArr.push({
        successCount: item?.successCount,
        failureCount: item?.failureCount,
        date: item?.date.slice(0, 5),
        fullDate: item?.date,
        stateCode: item?.stateCode,
        partialCount: item?.partialCount,
      })
    );
    const codes = data?.map((item: any) => item?.stateCode);
    const stateCodeArr = codes?.filter(
      (item: string, index: number) => codes?.indexOf(item) === index
    );
    progressArr.sort((a: any, b: any) => Date.parse(b.fullDate) - Date.parse(a.fullDate));

    dispatch(setStateCodes(stateCodeArr));
    dispatch(setBatchJobs(data));
    dispatch(setGraphData(progressArr));
    dispatch(setFilteredBatchJobs([]));
    dispatch(setFilteredGraphData([]));
  }

  const handleEBTOperation = async (index: number) => {
    dispatch(setEBTLoader(true))
    dispatch(setEBTStateCode(''));
    const issuance = await fetchEBTIssuanceLogs(envs[index], true, dispatch)
    const redemption = await fetchEBTRedemptionLogs(envs[index], true, dispatch)
    const ebtHealth = await fetchEBTHealth(envs[index])
    dispatch(setEBTHealth(ebtHealth));
    dispatch(setIssuance(issuance?.processIssuanceDetails));
    dispatch(setRedemption(redemption?.redemptionDetails));
    dispatch(setFilteredIssuance([]));
    dispatch(setFilteredRedemption([]));
  }

  const handleMyWicOperation = async (index: number) => {
    dispatch(setMyWicLoader(true))
    const services = await fetchMyWICMonitoring(envs[index], true, dispatch, '', '')
    const myWicHealth = await fetchMyWICHealth(envs[index])
    dispatch(setMyWicService(services))
    dispatch(setMyWicHealth(myWicHealth))
  }

  const handleFeatureFlags = async (index: number) => {
    dispatch(setCRMLoader(true))
    const data = await fetchFeatureFlags(envs[index], true, dispatch)
    dispatch(setCRMFlags(data?.result));
    dispatch(setCRMEnvDetails(data?.details));
  }

  const handleCRMPluginExceptions = async (index: number, pathname: string) => {
    dispatch(setPlExceptionStateCodesLoader(true))
    const stateCodes = await fetchStateCodes(envs[index], true);
    
    dispatch(setAllStateCodes(stateCodes))

    switch(pathname){
      case '/monitoring/crm/clientside_exceptions':
        dispatch(setClentSideExceptionStateCode(''));
        dispatch(setFilteredClientSideExceptions([]));
        dispatch(setCRMClientSideExceptions([]));
        dispatch(setClientSideExceptionsByProgram([]));
        dispatch(setClientSideExceptionsCountByDate([]));
        dispatch(setOrganisationName(''));
        break;
      case '/monitoring/crm/plugin_exceptions':
        dispatch(setPluginExceptionStateCode(''));
        dispatch(setFilteredPluginExceptions([]));
        dispatch(setPluginExceptions([]));
        dispatch(setExByPrograms([]));
        dispatch(setExByMessages([]));
        dispatch(setExByPlugins([]));
        dispatch(setExByDates([]))
        dispatch(setExMessagesByPluginName([]))
        dispatch(setOrganisationId(''));
        break;
    }
  }

  const handleStateCodeMenuItemClick = (
    event: any,
    checked: boolean,
    index: number
  ) => {
    var states = [...selectedStates];
    const updatedCheckedState = checkedState?.map((item, idx) => {
      return idx === index ? !item : item;
    });
    dispatch(setCheckedState(updatedCheckedState));
    if (event.target.checked) {
      states = [...selectedStates, event.target.value];
    } else {
      states.splice(states.indexOf(event.target.value), 1);
    }
    setSelectedStates(states);

  };

  const handleApply = async () => {
    const selectedStateCode = stateCodes?.filter(
      (item, index) => checkedState[index] === true
    );
    setIsFilterOpen(false);
    dispatch(setSelectedStateCodes(selectedStates));
    const filteredJobs = batchJobs?.filter((item: { stateCode: string; }) =>
      selectedStateCode?.includes(item?.stateCode)
    );
    const filteredGraphData = barGraphData?.filter((item: { stateCode: string; }) =>
      selectedStateCode.includes(item?.stateCode)
    );
    dispatch(setFilteredBatchJobs(filteredJobs));
    dispatch(setFilteredGraphData(filteredGraphData));
  };

  const clearFilter = async () => {
    setIsFilterOpen(false);
    setAnchorEl2(null);
    dispatch(setCheckedState(new Array(stateCodeLength).fill(false)));
    dispatch(clearSelectedStateCodes());
    setSelectedStates([]);
    dispatch(setFilteredBatchJobs([]));
    dispatch(setFilteredGraphData([]));
    dispatch(setGraphData(barGraphData));
    dispatch(setBatchJobs(batchJobs));
  };

  const handleEBTStatesMenu = async (event: any) => {
    setIsEBTFilterOpen(false)
    dispatch(setEBTStateCode(event.target.textContent));
    const filteredIssuance = issuance?.filter((item: { stateCode: string; }) => item.stateCode === event.target.textContent);
    const filteredRedemption = redemption?.filter((item: { stateCode: string; }) => item.stateCode === event.target.textContent);
    dispatch(setFilteredIssuance(filteredIssuance));
    dispatch(setFilteredRedemption(filteredRedemption));
  }

  const clearEBT = async () => {
    setIsEBTFilterOpen(false)
    dispatch(setEBTStateCode(''));
    dispatch(setFilteredIssuance([]));
    dispatch(setFilteredRedemption([]));  
  }

  const handleCRMFFMenu = async (event: any, item: any) => {
    setIsCrmFFOpen(false)
    dispatch(setCRMLoader(true));
    dispatch(setCRMStateCode(item.abbreviation));
    const crmFeatures = await fetchFeatureFlags(item.abbreviation, true, dispatch)
    dispatch(setCRMFlags(crmFeatures?.result));
    dispatch(setCRMEnvDetails(crmFeatures?.details));
    dispatch(setUnavailableFlags(crmFeatures?.featureFlagNotPresentInLowerInstance))
  }

  const handleCRMPluginMenu = async (event: any, item: any, pathname: string) => {
    setIsCrmOpen(false)
    switch(pathname){
      case '/monitoring/crm/plugin_exceptions':
        dispatch(setPluginExceptionStateCode(item.abbreviation));
        dispatch(setOrganisationId(item.organizationId));
        const filteredExceptions: any = crmPluginExceptions.filter((elem: any) => elem.organizationId === item.organizationId)
        dispatch(setFilteredPluginExceptions(filteredExceptions));
        getExCountByProgram(filteredExceptions, allStateCodes)
        getExMessage(filteredExceptions);
        getExCountByDate(filteredExceptions);
        if(dayjs(toDate).diff(dayjs(fromDate), 'days') === 0){
          getPluginCountByDatePieChart(filteredExceptions)
        } else {
          getPluginCountByDate(filteredExceptions);
        }
        break;
      case '/monitoring/crm/clientside_exceptions':
        const filteredClientSideExceptions: any = crmClientSideExceptions.filter((elem: any) => elem.organizationName === item.organizationName)
        dispatch(setClentSideExceptionStateCode(item.abbreviation));
        dispatch(setOrganisationName(item.organizationName));
        dispatch(setFilteredClientSideExceptions(filteredClientSideExceptions));
        getClientSideExByPrgram(filteredClientSideExceptions, allStateCodes);
        getClientSideExCountByDate(filteredClientSideExceptions);
        break;
    }
   

  }

  const clearPluginException = () => {
    setIsCrmOpen(false)
    dispatch(setPluginExceptionStateCode(''));
    dispatch(setOrganisationId(''));
    dispatch(setFilteredPluginExceptions([]));
    crmPluginExceptions && getExCountByProgram(crmPluginExceptions, allStateCodes)
    crmPluginExceptions &&  getExMessage(crmPluginExceptions);
    crmPluginExceptions &&  getExCountByDate(crmPluginExceptions);
    if(dayjs(toDate).diff(dayjs(fromDate), 'days') === 0){
      crmPluginExceptions && getPluginCountByDatePieChart(crmPluginExceptions)
    } else {
      crmPluginExceptions && getPluginCountByDate(crmPluginExceptions);
    }
    dispatch(setFilteredClientSideExceptions([]));
    getClientSideExByPrgram(crmClientSideExceptions, allStateCodes);
    getClientSideExCountByDate(crmClientSideExceptions);
    dispatch(setClentSideExceptionStateCode(''));
    dispatch(setOrganisationName(''));

  }

  return (
    <AppBar
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>
        <img src={logo} alt="Header Logo" />
        <Typography sx={{ px: 2, color: "#81629D" }}>|</Typography>
        <Typography variant="body2" sx={{ flexGrow: 1 }}>
          Operations
        </Typography>

        {(location.pathname === "/monitoring/jobs" || location.pathname === "/monitoring/ebt" || location.pathname === '/monitoring/mywic' || location.pathname.includes('/monitoring/crm' )) && (
          <div>
            <Button
              id="select-env"
              endIcon={<UnfoldMore />}
              sx={{ mr: 2 }}
              variant="contained"
              onClick={() => setIsOpen(!isOpen)}
            >
              <Typography variant="caption">{envs[selectedIndex]}</Typography>
            </Button>

            <Menu
              id="env-menu"
              open={isOpen}
              onClose={handleClose}
              sx={{ mr: 2 }}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {envs.map((item: String, index: number) => (
                <MenuItem
                  selected={index === selectedIndex}
                  onClick={(event) => handleMenuItemClick(event, index)}
                >
                  {item}
                </MenuItem>
              ))}
            </Menu>
          </div>
        )}

        {/* State Code Filter */}
        {location.pathname === "/monitoring/jobs" && stateCodes?.length && (
          <div>
            <Typography variant="caption">
              {selectedStates.length > 1
                ? `${selectedStates[0]}, ${selectedStates[1]} ...`
                : selectedStates[0]}
            </Typography>
            <Tooltip title="Select state codes">
              <IconButton onClick={handleFilter} size="medium" sx={{ ml: 2 }}>
                <FilterAlt sx={{ color: "white" }} />
              </IconButton>
            </Tooltip>
            <Menu
              id="state-code-menu"
              open={isFilterOpen}
              onClose={handleCloseFilter}
              anchorEl={anchorEl2}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              {stateCodes?.map((item: String, index: number) => {
                return (
                  <MenuItem>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={item}
                          checked={selectedStates.some(state => state === item)}
                          onChange={(event, checked) =>
                            handleStateCodeMenuItemClick(event, checked, index)
                          }
                        />
                      }
                      label={item}
                    />
                  </MenuItem>
                );
              })}
              <Box sx={{ justifyContent: "center", display: "flex", mt: 1 }}>
                <span>
                  <Button
                    sx={{ mx: 1 }}
                    variant="contained"
                    size="small"
                    onClick={handleApply}
                  >
                    Apply
                  </Button>
                  <Button
                    sx={{ mr: 1 }}
                    variant="contained"
                    size="small"
                    onClick={clearFilter}
                  >
                    Clear
                  </Button>
                </span>
              </Box>
            </Menu>
          </div>
        )}

        {/* State Code filter in EBT */}
        {(location.pathname === "/monitoring/ebt" && ebtStateCodes?.length) ?
          <div>
            <Typography variant="caption">
              {selectedEBTStateCode}
            </Typography>
            <Tooltip title="Select state code">
              <IconButton onClick={handleEBTFilter} size="medium" sx={{ ml: 2 }}>
                <FilterAlt sx={{ color: "white" }} />
              </IconButton>
            </Tooltip>
            <Menu
              id="state-code-menu"
              open={isEBTFilterOpen}
              onClose={handleCloseEBTFilter}
              anchorEl={anchorEl3}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              {ebtStateCodes?.map((item: any, index: number) => {
                return (
                  <MenuItem sx={{ mx: 2 }} onClick={handleEBTStatesMenu}>
                    {item.abbreviation}
                  </MenuItem>
                );
              })}
              <Box sx={{ justifyContent: "center", display: "flex", mt: 1 }}>
                <span>
                  <Button
                    sx={{ m: 'auto' }}
                    variant="contained"
                    size="small"
                    onClick={clearEBT}
                  >
                    Clear
                  </Button>
                </span>
              </Box>
            </Menu>
          </div>
        
        : location.pathname === "/management/ebt" && <CircularProgress color="inherit" />
      }


        {/* State Code filter in Features */}
        {(location.pathname === "/management/features" && crmFeatFlagStateCodes?.length) ? 
          <div>
            <Typography variant="caption">
              {selectedCRMStateCode}
            </Typography>
            <Tooltip title="Select state code">
              <IconButton onClick={handleCRMFilter} size="medium" sx={{ ml: 2 }}>
                <FilterAlt sx={{ color: "white" }} />
              </IconButton>
            </Tooltip>
            <Menu
              id="state-code-menu"
              open={isCrmFFOpen}
              onClose={handleCloseCRMFilter}
              anchorEl={anchorEl4}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              {allStateCodes?.map((item: any, index: number) => {
                return (
                  <MenuItem sx={{ mx: 2 }} onClick={(event) => handleCRMFFMenu(event, item)}>
                    {`${item.abbreviation}`}
                  </MenuItem>
                );
              })}
            </Menu>
          </div> 
        : (location.pathname === "/management/features") && <CircularProgress color="inherit" />}
        
        {/* Env filter for CRM*/}
        {location.pathname.includes("/monitoring/crm") && !crmPluginExceptionsStateCodeLoading ?
          <div>
            <Typography variant="caption">
              { location.pathname === "/monitoring/crm/plugin_exceptions" ? selectedPEStateCode : selectedCEStateCode}
            </Typography>
            <Tooltip title="Select state code">
              <IconButton onClick={handleCRMPlugins} size="medium" sx={{ ml: 2 }}>
                <FilterAlt sx={{ color: "white" }} />
              </IconButton>
            </Tooltip>
            <Menu
              id="state-code-menu"
              open={isCrmOpen}
              onClose={handleCloseCRM}
              anchorEl={anchorEl5}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              {allStateCodes?.map((item: any, index: number) => {
                return (
                  <MenuItem sx={{ mx: 2 }} onClick={(event) => handleCRMPluginMenu(event, item, location.pathname)}>
                    {`${item.abbreviation}`}
                  </MenuItem>
                );
              })}
              <Box sx={{ justifyContent: "center", display: "flex", mt: 1 }}>
                <span>
                  <Button
                    sx={{ m: 'auto' }}
                    variant="contained"
                    size="small"
                    onClick={clearPluginException}
                  >
                    Clear
                  </Button>
                </span>
              </Box>
            </Menu>
          </div> 
        : location.pathname.includes("/monitoring/crm") && <CircularProgress color="inherit" />
        }
      </Toolbar>
    </AppBar>
  );
};
