import { Cancel, CheckCircle, Error } from '@mui/icons-material';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { LoadingPage } from '../pages';
import { setEBTTabIndex } from '../store/ebtSlice';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

interface issuanceProps {
  date: string,
  duration: number,
  stateCode: string,
  totalIssuance: number,
  successCount: number,
  failureCount: number,
  percentOfSuccess: number,
  healthStatus: string,
  retrySuccessCount: number
}

interface redemptionProps {
  date: string,
  duration: number,
  stateCode: string,
  totalRedemption: number,
  successCount: number,
  failureCount: number,
  percentOfSuccess: number,
  healthStatus: string,
  retrySuccessCount: number,

}

interface ebtProps {
  issuance: Array<issuanceProps>
  redemption: Array<redemptionProps>
  isLoading: boolean
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const showHealthStatus = (status: string) => {
  const healthStatus: any = {
    Green: <CheckCircle color="success" />,
    Red: <Cancel color="error" />,
    Orange: <Error color="warning" />,
  };
  return healthStatus[status]
};

export const EBTTabs: React.FC<ebtProps> = ({ issuance, isLoading, redemption }) => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch()

  const handleChange = async (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    dispatch(setEBTTabIndex(newValue));
  };

  const onRowClick = (successCount: number, failureCount: number) => {
    // console.log({ successCount, failureCount })
  }

  const convertMsToSec = (time: number): string => {
    if (time > 1000) {
      return `${(time / 1000).toFixed(1)} s`;
    }
    return `${time.toFixed(1)} ms`;
  }

  return (
    <Box sx={{ bgcolor: 'background.paper', width: '100%' }}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
          TabIndicatorProps={{ style: { background: 'white' } }}
        >
          <Tab label="Issuance" {...a11yProps(0)} />
          <Tab label="Redemption" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} dir={theme.direction}>
        {
          isLoading ? <LoadingPage /> : <TableContainer component={Paper}>
            <Table aria-label="issuance table">
              <TableHead sx={{ bgcolor: "#4A2E62" }}>
                <TableRow>
                  <TableCell align='center' style={{ minWidth: 80, color: "white" }}>State Code</TableCell>
                  <TableCell align='center' style={{ minWidth: 80, color: "white" }}>Date</TableCell>
                  <TableCell align='center' style={{ minWidth: 80, color: "white" }}>Avg Duration</TableCell>
                  <TableCell align='center' style={{ minWidth: 80, color: "white" }}>Total Count</TableCell>
                  <TableCell align='center' style={{ minWidth: 80, color: "white" }}>Success Count</TableCell>
                  <TableCell align='center' style={{ minWidth: 80, color: "white" }}>Retry Success Count</TableCell>
                  <TableCell align='center' style={{ minWidth: 80, color: "white" }}>Failure Count</TableCell>
                  <TableCell align='center' style={{ minWidth: 80, color: "white" }}>Health Status</TableCell>
                  <TableCell align='center' style={{ minWidth: 80, color: "white" }}>Success Issuance</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {issuance?.map(({ stateCode, date, duration, totalIssuance, successCount, failureCount, healthStatus, percentOfSuccess, retrySuccessCount }, index) => (
                  <TableRow key={index} onClick={() => onRowClick(successCount, failureCount)}>
                    <TableCell align='center'>{stateCode}</TableCell>
                    <TableCell align='center'>{date}</TableCell>
                    <TableCell align='center'>{convertMsToSec(duration)}</TableCell>
                    <TableCell align='center'>{totalIssuance}</TableCell>
                    <TableCell align='center'>{successCount}</TableCell>
                    <TableCell align='center'>{retrySuccessCount}</TableCell>
                    <TableCell align='center'>{failureCount}</TableCell>
                    <TableCell align='center'>{showHealthStatus(healthStatus)}</TableCell>
                    <TableCell align='center'>{(percentOfSuccess * 100).toFixed(1)} %</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        }
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
        {
          isLoading ? <LoadingPage /> : <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="redemption table">
              <TableHead sx={{ bgcolor: "#4A2E62" }}>
                <TableRow>
                  <TableCell align='center' style={{ minWidth: 80, color: "white" }}>State Code</TableCell>
                  <TableCell align='center' style={{ minWidth: 80, color: "white" }}>Date</TableCell>
                  <TableCell align='center' style={{ minWidth: 80, color: "white" }}>Avg Duration</TableCell>
                  <TableCell align='center' style={{ minWidth: 80, color: "white" }}>Total Count</TableCell>
                  <TableCell align='center' style={{ minWidth: 80, color: "white" }}>Success Count</TableCell>
                  <TableCell align='center' style={{ minWidth: 80, color: "white" }}>Retry Success Count</TableCell>
                  <TableCell align='center' style={{ minWidth: 80, color: "white" }}>Failure Count</TableCell>
                  <TableCell align='center' style={{ minWidth: 80, color: "white" }}>Health Status</TableCell>
                  <TableCell align='center' style={{ minWidth: 80, color: "white" }}>Success Redemption</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {redemption?.length ? redemption?.map(({ stateCode, date, duration, totalRedemption, successCount, failureCount, healthStatus, percentOfSuccess, retrySuccessCount }, index) => (
                  <TableRow key={index} onClick={() => onRowClick(successCount, failureCount)}>
                    <TableCell align='center'>{stateCode}</TableCell>
                    <TableCell align='center'>{date}</TableCell>
                    <TableCell align='center'>{convertMsToSec(duration)}</TableCell>
                    <TableCell align='center'>{totalRedemption}</TableCell>
                    <TableCell align='center'>{successCount}</TableCell>
                    <TableCell align='center'>{retrySuccessCount}</TableCell>
                    <TableCell align='center'>{failureCount}</TableCell>
                    <TableCell align='center'>{showHealthStatus(healthStatus)}</TableCell>
                    <TableCell align='center'>{(percentOfSuccess * 100).toFixed(1)} %</TableCell>
                  </TableRow>
                )) : <TableRow>
                  <TableCell align="center" > -- No Data -- </TableCell>
                </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
        }
      </TabPanel>
    </Box>
  );
}
