import { useState } from "react";

import { Box } from "@mui/material";

import { Header } from "./Header";
import { Navigation } from "./Navigation";

import { Outlet } from "react-router";

const drawerWidth = 256

export const RootLayout = () => {

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      <Header mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
      <Navigation mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
      <Box component="main" sx={{ flex: 1, p: 4, overflowY: 'scroll', mt:(theme) => `${theme.mixins.toolbar.minHeight}px`, height: (theme) => `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,  ml: { sm: `${drawerWidth}px` } }}>
        <Outlet />
      </Box>
    </>
  );
};
