import {
  Cancel,
  CheckCircle,
  Error
} from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@mui/material";
import React from "react";

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

type Service = {
  id: string;
  title: string;
  day0: string;
  day1: string;
  day2: string;
  day3: string;
  day4: string;
  day5: string;
  day6: string;
  services?: Service[];
};

type jobStatus = {
  healthStatus: string;
  date: string;
};

interface ServiceStatusProps {
  jobStatus: jobStatus[];
  jobName?: string;
}

export const ServiceStatus: React.FC<ServiceStatusProps> = (props) => {
  
  const { jobStatus, jobName = "Some Job" } = props;
  var objKeys: any = []

  jobStatus.forEach((item, index) => {
    var key = `day${index}`
    objKeys.push(key)
  })
  var obj: any = {}

  jobStatus.map((item: any, index: number) => {
     obj[objKeys[index]] = item.healthStatus
    return obj;
  });

  jobStatus?.sort((a: any, b: any) => Date.parse(b.date) -  Date.parse(a.date));

  const data: Service[] = [
    {
      id: "batch",
      title: jobName,
      ...obj
    },
  ];

  const renderStatusCell = (status: string) => {
    switch (status) {
      case "Green":
        return <CheckCircle color="success" />;
      case "Red":
        return <Cancel color="error" />;
      case "Orange":
        return <Error color="warning" />;
      default:
        return <></>
    }
  };

  const getDate = (date:string): string => {
    const newDate = new Date(date);
    const formattedDate = `${monthNames[newDate.getMonth()]} ${newDate.getDate()}`;
    return formattedDate;
  };

  const renderBody = (services: Service[], level: number = 0) => {
    return (
      <TableBody>
        {services.map((service) => (
          <Row key={service.title} service={service} level={level} />
        ))}
      </TableBody>
    );
  };

  type RowProps = {
    service: Service;
    level: number;
  };
  const Row = ({ service, level }: RowProps) => {
    const [open, setOpen] = React.useState(false);
    return (
      <>
        <TableRow>
          <TableCell sx={{ m: 0, p: 0 }} width={50}>
            {service.services && (
              <IconButton size="small" onClick={() => setOpen(!open)}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            )}
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            width={300}
            sx={{ paddingLeft: level * 3 }}
          >
            {service.title}
          </TableCell>
          <TableCell style={{ minWidth: 80 }} align="center">
            {renderStatusCell(service.day0)}
          </TableCell>
          <TableCell style={{ minWidth: 80 }} align="center">
            {renderStatusCell(service.day1)}
          </TableCell>
          <TableCell style={{ minWidth: 80 }} align="center">
            {renderStatusCell(service.day2)}
          </TableCell>
          <TableCell style={{ minWidth: 80 }} align="center">
            {renderStatusCell(service.day3)}
          </TableCell>
          <TableCell style={{ minWidth: 80 }} align="center">
            {renderStatusCell(service.day4)}
          </TableCell>
          <TableCell style={{ minWidth: 80 }} align="center">
            {renderStatusCell(service.day5)}
          </TableCell>
          <TableCell style={{ minWidth: 80 }} align="center">
            {renderStatusCell(service.day6)}
          </TableCell>
        </TableRow>
      </>
    );
  };

  return (
    <Box>
      <Box border={1} sx={{ borderColor: "grey.300" }}>
        <Table size="small">
          <TableHead sx={{ bgcolor: "#4A2E62" }}>
            <TableRow>
              <TableCell />
              <TableCell align="left" sx={{ p: 0, color: "white" }}>
                Service
              </TableCell>
              {
                jobStatus.map((item: any, index)=> {
                  return <TableCell
                  align="center"
                  style={{ minWidth: 80, color: "white" }}
                >
                  {getDate(item.date)}
                </TableCell>
                })
              }
            </TableRow>
          </TableHead>
          {renderBody(data)}
        </Table>
      </Box>
    </Box>
  );
};
