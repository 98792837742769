import {
  ArrowForward,
  Cancel,
  CheckCircle,
  Error,
  FilterList,
  Refresh,
  Replay,
  Stop
} from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  Chip,
  LinearProgress,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  styled
} from "@mui/material";

import {
  DataGrid,
  GridActionsCellItem,
  GridActionsCellItemProps,
  GridCallbackDetails,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
  GridValueGetterParams,
  MuiEvent
} from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BatchJobsTable } from "../../components/BatchJobsTable";
import { DailyProgressChart } from "../../components/DailyProgressChart";
import { ROUTES } from "../../config/Routes";
import { fetchGraph, fetchLogs } from "../../network/apiCalls";
import { setBatchJobs, setFilteredBatchJobs, setFilteredGraphData, setGraphData } from "../../store/batchJobsSlice";
import { setStateCodes } from "../../store/dropdownSlice";
import {
  getBatchJobs,
  getEnv,
  getFilteredGraph,
  getFilteredJobs,
  getGraphData,
  getLoadingStatus,
  getSelectedStateCodes
} from "../../store/storeSelectors";
import { LoadingPage } from "../LoadingPage";

const status = (status?: string) => {
  if (status === "Success") {
    return (
      <Chip size="small" sx={{ fontSize: 11 }} color="success" label={status} />
    );
  }
  if (status === "Partial Success") {
    return (
      <Chip
        size="small"
        sx={{ fontSize: 11 }}
        color="warning"
        style={{ color: "white" }}
        label="Partial"
      />
    );
  }
  if (status === "Failed") {
    return (
      <Chip size="small" sx={{ fontSize: 11 }} color="error" label="Failed" />
    );
  }
  if (status === "Running" || status === "Starting") {
    return (
      <Chip size="small" sx={{ fontSize: 11 }} color="default" label={status} />
    );
  }
  return <></>;
};

const showHealthStatus = (status: string) => {
  const healthStatus: any = {
    Green: <CheckCircle color="success" />,
    Red: <Cancel color="error" />,
    Orange: <Error color="warning" />,
  };
  return healthStatus[status]

};

const actions = (params: GridRowParams) => {
  const actions: React.ReactElement<GridActionsCellItemProps>[] = [];
  if (params.row.status === "Running") {
    actions.push(<GridActionsCellItem icon={<Stop />} label="Stop" />);
  }
  if (
    params.row.status === "Failed" ||
    params.row.status === "Partial Success"
  ) {
    actions.push(<GridActionsCellItem icon={<Replay />} label="Restart" />);
  }
  return actions;
};

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: "20px",
  width: "100%",
  borderRadius: "8px",
  "& .MuiLinearProgress-bar": {
    transition: "none",
    transformOrigin: "left",
  },
}));

const columns: GridColDef[] = [
  {
    field: "jobName",
    headerName: "Job Name",
    disableColumnMenu: true,
    width: 250,
    headerClassName: "super-app-theme--header",
    renderCell: (params: GridRenderCellParams<any, string>) => {
      return <Tooltip title={params?.value}><p>{params?.value}</p></Tooltip>
    },
  },
  {
    field: "healthStatus",
    headerName: "Health Status",
    align: "center",
    headerAlign: "center",
    width: 150,
    headerClassName: "super-app-theme--header",
    disableColumnMenu: true,
    renderCell: (params) => {
      return showHealthStatus(params?.value)
    }
  },
  {
    field: "timeStarted",
    type: "dateTime",
    headerName: "Time Started",
    headerClassName: "super-app-theme--header",
    width: 160,
    valueGetter: (params: GridValueGetterParams<any, string>) => {
      return params.value ? new Date(params.value) : null;
    },
  },
  {
    field: "timeStopped",
    type: "dateTime",
    headerName: "Time Stopped",
    width: 160,
    headerClassName: "super-app-theme--header",
    valueGetter: (params: GridValueGetterParams<any, string>) => {
      return params.value ? new Date(params.value) : null;
    },
  },
  {
    field: "duration",
    headerName: "Duration",
    width: 130,
    headerClassName: "super-app-theme--header",
    valueGetter: (params: GridValueGetterParams<any, number>) => {
      return params.value ? params.value.toFixed(1) : null;
    },
  },
  {
    field: "stateCode",
    headerName: "State Code",
    width: 120,
    headerClassName: "super-app-theme--header",
    disableColumnMenu: true,
    sortable: false,
  },
  {
    field: "percentComplete",
    headerName: "Progress",
    width: 250,
    headerClassName: "super-app-theme--header",
    renderCell: (params: GridRenderCellParams<any, number>) => {
      if (params.value) {
        var percentage = Math.round(params.value * 100);
        return (
          <Tooltip
            title={
              <>
                <Typography>Success: {params.row.successCount}</Typography>
                <Typography>Failure: {params.row.failureCount}</Typography>
                <Typography>Total: {params.row.totalCount}</Typography>
              </>
            }
          >
            <div
              style={{ width: "100%", position: "relative", display: "flex" }}
            >
              <BorderLinearProgress
                style={{ display: "inline-block" }}
                sx={{ mr: 2 }}
                variant="determinate"
                value={percentage}
                color="info"
              />
              <Typography
                color="white"
                style={{
                  position: "absolute",
                  width: "100%",
                  display: "inline-block",
                  textAlign: "center",
                }}
                width={70}
                variant="caption"
              >
                {percentage}%
              </Typography>
            </div>
          </Tooltip>
        );
      } else {
        return null;
      }
    },
  },
  {
    field: "status",
    headerName: "Status",
    align: "center",
    headerAlign: "center",
    width: 120,
    headerClassName: "super-app-theme--header",
    disableColumnMenu: true,
    renderCell: (params: GridRenderCellParams<any, string>) =>
      status(params.value),
  },
  {
    field: "actions",
    type: "actions",
    headerName: "Actions",
    width: 100,
    headerClassName: "super-app-theme--header",
    getActions: (params: GridRowParams) => actions(params),
  },
];
const time: any = {
  'Day': 'Daily',
  'Hour': 'Hourly',
  'Month': 'Monthly'
}

const showTiming = (value: string) => {
  return time[value]
}

export const MonitoringJobsPage = () => {
  const batchJobs = useSelector(getBatchJobs);
  const selectedEnv = useSelector(getEnv);
  const dailyJobsHistory = useSelector(getGraphData);
  const selectedStateCodes: string[] = useSelector(getSelectedStateCodes);
  const graphData = useSelector(getGraphData);
  const showLoader = useSelector(getLoadingStatus);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [frequency, setFrequency] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const filteredBatchJobs = useSelector(getFilteredJobs)
  const filteredGraphData = useSelector(getFilteredGraph)

  const open = Boolean(anchorEl);

  const showPartial = true;

  const { isLoading, isError, data, refetch, isFetching } = useQuery({
    queryKey: ["monitor"],
    queryFn: () => fetchLogs(selectedEnv, true, dispatch),
  });

  const graphQuery = useQuery({
    queryKey: ["graph"],
    queryFn: () => fetchGraph(selectedEnv, false ,null),                  
  });

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {

    let progressArr: any = [];

    graphQuery?.data?.map((item: any, index: number) =>
      progressArr.push({
        successCount: item?.successCount,
        failureCount: item?.failureCount,
        date: item?.date.slice(0, 5),
        fullDate: item?.date,
        stateCode: item?.stateCode,
        partialCount: item?.partialCount,
      })
    );
    progressArr.sort((a: any, b: any) => Date.parse(b.fullDate) - Date.parse(a.fullDate));

    dispatch(setGraphData(progressArr));
    dispatch(setBatchJobs(data));
  
  }, [data, dispatch, graphQuery.data]);

  useEffect(() => {
    const codes = data?.map((item: any) => item?.stateCode);
    const stateCodeArr = codes?.filter(
      (item: string, index: number) => codes?.indexOf(item) === index
    );
    dispatch(setStateCodes(stateCodeArr));
    const freq = data?.map((item: any) => item.frequency).filter((item: string, index: number, arr: string[]) => arr.indexOf(item) === index)
    setFrequency(freq);
  }, [data, dispatch]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Button
          size="small"
          startIcon={<Refresh fontSize="small" />}
          onClick={() => refetch()}
        >
          Refresh
        </Button>
        <GridToolbarExport />
        <Box style={{ flex: 1 }}></Box>
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
  }

  const onRowItemClick = (
    params: GridRowParams,
    event: MuiEvent,
    details: GridCallbackDetails
  ) => {
    navigate(
      ROUTES.monitoringJobInstance.path.replace(
        ":instanceId",
        params.row.instanceId
      ),
      {
        state: { jobName: params.row.jobName, stateCode: params.row.stateCode },
      }
    );
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTimeFilter = (event: any,
    index: number) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    const key = Object.keys(time).find(key => time[key] === event?.target?.textContent)
    let filterdData = []
    if(filteredBatchJobs.length){
      filterdData = data?.filter((item: { stateCode: string; frequency: string | undefined }) => item.frequency === key && selectedStateCodes.includes(item.stateCode))
      dispatch(setFilteredBatchJobs(filterdData));
    }else {
      filterdData = data?.filter((item: { stateCode: string; frequency: string | undefined }) => item.frequency === key)
      dispatch(setBatchJobs(filterdData));
    }
  }

  const clearTimeFilter = () => {
    if (selectedStateCodes.length) {
      const filterdData = data?.filter((item: { stateCode: string }) => selectedStateCodes.includes(item.stateCode))
      dispatch(setFilteredBatchJobs(filterdData));
    } else {
      dispatch(setBatchJobs(data));
    }
    setSelectedIndex(-1);
    setAnchorEl(null);
  }

  return (
    <Box>
      {
        showLoader && <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={showLoader}
        >
          <LoadingPage />
        </Backdrop>
      }
      <Typography variant="h6" component="h1" pb={2}>
        Batch Jobs
      </Typography>
      <Typography variant="h6" component="h2" pb={2}>
        Overview
      </Typography>
      {isLoading ? (
        []
      ) : selectedStateCodes?.length ? <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center', }}>
        <DailyProgressChart data={filteredGraphData.length ? filteredGraphData : dailyJobsHistory} showPartial={showPartial} /> 
        { selectedStateCodes?.length === 1 && <Box sx={{ margin: 'auto', borderWidth: "1px", borderStyle: 'solid', borderColor: '#ccc', borderRadius: 100, padding: "30px 35px" }}>
          <Tooltip title="For more Info on batch jobs, click here!" onClick={()=> navigate(ROUTES.batchJobs.path, {state: {stateCode: selectedStateCodes[0]}})}>
            <ArrowForward fontSize='large' color={"inherit"}/>
            </Tooltip>
          </Box> }
      </Box>
      : <BatchJobsTable />}
      <Typography variant="h6" component="h2" py={2}>
        History
      </Typography>
      {batchJobs?.length ? (
        <>
          <Box sx={{ border: "1px solid #ccc" }}>
            <Button
              id="demo-positioned-button"
              aria-controls={open ? 'demo-positioned-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              startIcon={<FilterList />}
            >
              Time filter
            </Button>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              {
                frequency?.map((item: string, index: number) => (
                  <MenuItem
                    selected={index === selectedIndex}
                    onClick={(event) => handleTimeFilter(event, index)}
                    sx={{ color: index === selectedIndex ? '#4A2E62' : '#000' }}
                  >
                    {showTiming(item)}
                  </MenuItem>
                ))
              }
              <Box sx={{ justifyContent: "center", display: "flex", mt: 1 }}>
                <Button
                  sx={{ m: 'auto' }}
                  variant="contained"
                  size="small"
                  onClick={clearTimeFilter}
                >
                  Clear
                </Button>
              </Box>
            </Menu>
          </Box>
          <DataGrid
            density="compact"
            autoHeight
            rows={isLoading ? [] : filteredBatchJobs?.length ? filteredBatchJobs : batchJobs}
            loading={isLoading || isFetching}
            columns={columns}
            getRowId={(row) => row.instanceId}
            sx={{
              fontSize: 12,
              "& .super-app-theme--header": {
                backgroundColor: "#4A2E62",
                color: "white",
              },
              "& .MuiDataGrid-sortIcon": {
                opacity: 1,
                color: "white",
              },
            }}
            pagination
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            disableRowSelectionOnClick
            onRowClick={onRowItemClick}
            slots={{
              toolbar: CustomToolbar,
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[5, 10, 15, 20, 50]}
          />
        </>
      ) : (
        <LoadingPage />
      )
      }

    </Box>
  );
};