import {
  CreditCard,
  DashboardOutlined,
  Devices,
  HomeOutlined,
  Monitor,
  PersonAddAlt,
  SmsOutlined,
  SvgIconComponent,
  ToggleOnOutlined,
  UpdateOutlined
} from '@mui/icons-material';
  
  
  import { ROUTES } from './Routes';
  
  export interface MenuItem {
    name: string;
    path?: string;
    icon?: SvgIconComponent
    items?: MenuItem[]
  };
  
  export const MENU: MenuItem[] = [
    {
      name: ROUTES.home.title,
      path: ROUTES.home.path,
      icon: HomeOutlined,
    },
    {
      name: 'Management',
      items: [
        {
          name: ROUTES.managementFeatures.title,
          path: ROUTES.managementFeatures.path,
          icon: ToggleOnOutlined,
        },
      ]
    },
    {
      name: 'Monitoring',
      items: [
        {
          name: ROUTES.monitoringOverview.title,
          path: ROUTES.monitoringOverview.path,
          icon: DashboardOutlined,
        },
        {
          name: ROUTES.monitoringJobs.title,
          path: ROUTES.monitoringJobs.path,
          icon: UpdateOutlined,
        },
        {
          name: ROUTES.monitoringCrm.title,
          path: ROUTES.monitoringCrm.path,
          icon: Monitor,
          items: [
            {
              name: ROUTES.monitoringCrmPlugins.title,
              path: ROUTES.monitoringCrmPlugins.path,
            },
            {
              name: ROUTES.monitoringClientSideExceptions.title,
              path: ROUTES.monitoringClientSideExceptions.path,
            },
            {
              name: ROUTES.monitoringCrmAvail.title,  
              path: ROUTES.monitoringCrmAvail.path,
            },
          ]
        },
        {
          name: ROUTES.monitoringEbt.title,
          path: ROUTES.monitoringEbt.path,
          icon: CreditCard,
        },
        {
          name: ROUTES.monitoringSms.title,
          path: ROUTES.monitoringSms.path,
          icon: SmsOutlined,
        },
        {
          name: ROUTES.monitoringMyWic.title,
          path: ROUTES.monitoringMyWic.path,
          icon: Devices,
        },
      ]
    },
    {
      name: 'Security',
      items: [
        {
          name: ROUTES.securityAccessRequests.title,
          path: ROUTES.securityAccessRequests.path,
          icon: PersonAddAlt,
        },
      ]
    },
  ];