import axios from "axios";
import { setGraphLoader, setLoader } from "../store/batchJobsSlice";
import { setEBTLoader } from "../store/ebtSlice";
import { setMyWicLoader } from "../store/myWicSlice";
import { setClientSideExceptionLoader, setCRMLoader, setPlExceptionStateCodesLoader, setPluginExceptionLoader } from "../store/crmSlice";
import store from "../store/store";

export const fetchInstance = (selectedEnv: string, instanceId: string) =>
  axios
    .get(`/monitor/GetDetailsByInstanceId`, {
      params: { Environment: selectedEnv === 'Production' ? 'PROD' : selectedEnv, InstanceId: instanceId },
    })
    .then((response) => response.data);

export const fetchJobNameDetails = (
  selectedEnv: string,
  jobName: string,
  stateCode?: string
) =>
  axios.get("/monitor/GetDetailsByJobName", {
    params: {
      Environment: selectedEnv === 'Production' ? 'PROD' : selectedEnv,
      JobName: jobName,
      StateCode: stateCode,
      Days: 7,
    },
  });

export const fetchLogs = (selectedEnv: string, call: boolean, dispatch?: any) => {
  return axios
    .get("/monitor", { params: { Environment: selectedEnv === 'Production' ? 'PROD' : selectedEnv } })
    .then((response) => {
      if (call) {
        dispatch(setLoader(false));
      }
      return response.data;
    });
}

export const fetchGraph = (selectedEnv: string, call: boolean, dispatch?: any, days?: number) => {
  return axios
    .get("/monitor/GetBatchJobsSucessFailDetails", {
      params: { Environment: selectedEnv === 'Production' ? 'PROD' : selectedEnv, Days: 7 },
    })
    .then((response) => {
      if(call){
        dispatch(setGraphLoader(false));
      }
      return response.data
    });
};

export const fetchBatchJobDetails = (selectedEnv: string, stateCode?: string) => {
  return axios
    .get("Monitor/GetMasterListOfBatchJobDetails", {
      params: { Environment: selectedEnv === 'Production' ? 'PROD' : selectedEnv, State: stateCode },
    })
    .then((response) => response.data);
};

export const fetchStateCodes = (selectedEnv: string, call?: boolean) => {
  return axios
    .get("/programs", {
      params: { Environment: selectedEnv === 'Production' ? 'PROD' : selectedEnv},
    })
    .then((response) =>{
      if(call){
        store.dispatch(setPlExceptionStateCodesLoader(false))
      }
      return response.data
    } );
};

export const fetchWeeklyHealth = (
  selectedEnv: string,
  jobName: string,
  stateCode: string
) =>
  axios.get("/monitor/GetWeeklyHealthByJobName", {
    params: {
      Environment: selectedEnv === 'Production' ? 'PROD' : selectedEnv,
      JobName: jobName,
      StateCode: stateCode,
    },
  });

export const fetchEBTIssuanceLogs = (selectedEnv: string, call: boolean, dispatch?: any) => {

  return axios
    .get("/EBT/GetProcessIssuance", { params: { Environment: selectedEnv === 'Production' ? 'PROD' : selectedEnv, } })
    .then((response) => {
      if (call) {
        dispatch(setEBTLoader(false));
      }
      return response.data;
    });
}

export const fetchEBTRedemptionLogs = (selectedEnv: string, call: boolean, dispatch?: any) =>
  axios
    .get("/EBT/GetRedemptions", { params: { Environment: selectedEnv === 'Production' ? 'PROD' : selectedEnv } })
    .then((response) => {
      if (call) {
        dispatch(setEBTLoader(false));
      }
      return response.data
    });


export const fetchEBTHealth = (selectedEnv: string) =>
  axios
    .get("/EBT/Health", { params: { Environment: selectedEnv === 'Production' ? 'PROD' : selectedEnv } })
    .then((response) => {
      return response.data
    });

export const fetchMyWICHealth = (selectedEnv: string) =>
  axios
    .get("/myWic/Health", { params: { Environment: selectedEnv === 'Production' ? 'PROD' : selectedEnv } })
    .then((response) => {
      return response.data
    });

export const fetchMyWICMonitoring = (selectedEnv: string, call: boolean, dispatch?: any, startDate?: string, endDate?: string,) =>
  axios
    .get("/myWic/SuccessFailCount", { params: { Environment: selectedEnv === 'Production' ? 'PROD' : selectedEnv, startDate: startDate, endDate: endDate } })
    .then((response) => {
      if (call) {
        dispatch(setMyWicLoader(false));
      }
      return response.data
    });

export const fetchMyWICExceptions = (selectedEnv: string, operationName: string) => {
  axios
    .get("/myWic/SuccessFailCountByOperationName", { params: { Environment: selectedEnv === 'Production' ? 'PROD' : selectedEnv, OperationName: operationName } })
    .then((response) => {
      return response.data
    });
}

export const fetchFeatureFlags = (stateCode: string, call: boolean, dispatch?: any) => {
  return axios
    .get("/Environments/Configurations", { params: { StateName : stateCode} })
    .then((response) => {
      if (call) {
        dispatch(setCRMLoader(false));
      }
      return response.data
    });
}

export const fetchCrmPluginExceptions = (selectedEnv: string, orgId: string, startDate: string, endDate: string,  call: boolean, isManagedException?: string,) => {
  return axios
    .get('CRM', {
      params: {
        Environment: selectedEnv === 'Production' ? 'PROD' : selectedEnv,
        OrganizationId: orgId,
        StartDate: startDate,
        EndDate: endDate,
        IsManagedException: isManagedException?.toLowerCase() as unknown as boolean
      }
    })  
    .then((response) => {
      if (call) {
        store.dispatch(setPluginExceptionLoader(false))
      }
      return response.data
    }).catch(e => {
        store.dispatch(setPluginExceptionLoader(false))
    })
}

export const fetchCrmClientSideExceptions = (selectedEnv: string, orgName: string, startDate: string, endDate: string, call: boolean) => {
  return axios
    .get('/CRM/GetClientSideException', {
      params: {
        Environment: selectedEnv === 'Production' ? 'PROD' : selectedEnv,
        OrganizationName: orgName,
        StartDate: startDate,
        EndDate: endDate
      }
    })
    .then((response) => {
      if (call) {
        store.dispatch(setClientSideExceptionLoader(false))
      }
      return response.data
    }).catch(e => {
        store.dispatch(setClientSideExceptionLoader(false))
    })
}

