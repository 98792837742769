import { createTheme } from "@mui/material/styles";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const baseTheme = createTheme({
    palette: {
        primary: {
            light: '#D1C2E1',
            main: '#4A2E62',
            dark: '#290E41',
        },
        secondary: {
            main: '#007599',
            light: '#007599',
            dark: '#006c8d',
        },
        error: {
            main: '#b95564'
        },
        success: {
            main: '#30824e'
        },
        warning: {
            main: '#df8665'
        },
        info: {
            main: '#808085'
        }
    },
    mixins: {
        toolbar: {
            minHeight: 48,
        },
    },
});

export const theme = createTheme(baseTheme, {
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: baseTheme.palette.primary.dark
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#EFEFF7',
                    borderRight: 'none',
                    boxShadow: baseTheme.shadows[3]
                },
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    backgroundColor: '#EFEFF7',
                    color: '#007599'
                }
            }
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        backgroundColor: '#F7F7FB',
                        color: '#000000',
                    },
                    '&.Mui-selected:hover': {
                        backgroundColor: '#FFFFFF',
                        color: '#000000',
                    }
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
                contained: {
                    boxShadow: 'none',
                    '&:active': {
                        boxShadow: 'none',
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    padding: baseTheme.spacing(1),
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    borderRadius: 4,
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgb(255,255,255,0.15)',
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontSize: 13,
                    fontWeight: baseTheme.typography.fontWeightMedium,
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                    minWidth: 'auto',
                    marginRight: baseTheme.spacing(2),
                    '& svg': {
                        fontSize: 18,
                    },
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    width: 32,
                    height: 32,
                },
            },
        },
    },
});