import { createSlice } from "@reduxjs/toolkit";

export type selectedStateProps = {
  name: string;
  isChecked: boolean;
};

const initialState = {
  env: "PROD",
  stateCodes: [],
  selectedStateCodes: [],
  checkedState: [],
  dateRange: 1,
  ebtStateCodes: [],
  ebtRedemptionStateCodes: [],
  allStateCodes : [],
  orgId: '',
  orgName: ''

};

const dropdownSlice = createSlice({
  name: "dropdownlist",
  initialState,
  reducers: {
    setEnv: (state, action) => {
      state.env = action.payload;
    },
    setSelectedStateCodes: (state: any, action) => {
      const arr = [...action.payload];
      state.selectedStateCodes = arr?.filter(
        (item: string, index: number) => arr?.indexOf(item) === index
      );
    },
    setStateCodes: (state: any, action) => {
      state.stateCodes = action.payload;
    },
    setCheckedState: (state, action) => {
      state.checkedState = action.payload;
    },
    clearSelectedStateCodes: (state: any) => {
      state.selectedStateCodes = [];
    },
    setDateRange: (state: any, action) => {
      state.dateRange = action.payload;
    },
    setEBTStateCodes: (state, action) => {
      state.ebtStateCodes = action.payload;
    },
    setEBTRedemptionStateCodes: (state, action) => {
      state.ebtRedemptionStateCodes = action.payload;
    },
    setAllStateCodes: (state, action) => {
      state.allStateCodes = action.payload
    },
    setOrganisationId: (state, action) => {
      state.orgId = action.payload
    },
    setOrganisationName: (state, action) => {
      state.orgName = action.payload
    },
  },
});

export const {
  setEnv,
  setSelectedStateCodes,
  setStateCodes,
  setCheckedState,
  clearSelectedStateCodes,
  setDateRange,
  setEBTStateCodes,
  setEBTRedemptionStateCodes,
  setAllStateCodes,
  setOrganisationId,
  setOrganisationName
} = dropdownSlice.actions;

export default dropdownSlice.reducer;
