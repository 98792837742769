import { Box, Typography } from '@mui/material';

export const Availability = () => {
    return (
        <Box>
            <Typography variant="h6" component="h1" pb={2}>
                Availability
            </Typography>
        </Box>
    )
}