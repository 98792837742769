import { Box, Typography } from "@mui/material";
import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Rectangle,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

export type progressCount = {
  successCount: number;
  failureCount: number;
  date: string;
};

interface progressChartProps {
  data: Array<progressCount>;
  showPartial?: boolean;
}

const CustomTooltip = ({ active, payload, showPartial }: any) => {
  if (active && payload && payload.length) {
    return (
      <Box
        sx={{
          backgroundColor: "white",
          borderColor: "primary.main",
          borderRadius: 2,
          borderStyle: "solid",
          borderWidth: 1,
          p: 2,
        }}
      >
        
        {showPartial && (
          <Typography>{`${payload[0].payload.fullDate}`}</Typography>
        )}
        {showPartial && (
          <Typography  sx={{ color: '#4A2E62'}}>{`StateCode: ${payload[0].payload.stateCode}`}</Typography>
        )}
        <Typography
          sx={{ color: payload[1].color ?? "#388e3c", py: 0.25 }}
        >{`Success Count: ${payload[0].payload.successCount}`}</Typography>
        <Typography
          sx={{ color: payload[0].color ?? "#B80000", py: 0.25 }}
        >{`Failure Count: ${payload[0].payload.failureCount}`}</Typography>
      </Box>
    );
  }
  return null;
};

export const DailyProgressChart: React.FC<progressChartProps> = (props) => {
  const { data, showPartial } = props;
  return (
    <Box sx={{ display: "flex" }}>
      <BarChart
        width={800}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 10,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip content={<CustomTooltip showPartial={showPartial} />} />
        <Legend />
        <Bar
          name="Failure Count"
          dataKey="failureCount"
          fill="#B80000"
          activeBar={<Rectangle fill="#EF4040" />}
        />
        <Bar
          name="Success Count"
          dataKey="successCount"
          fill="#388e3c"
          activeBar={<Rectangle fill="#65B741" />}
        />
      </BarChart>
    </Box>
  );
};
