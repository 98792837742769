import { Configuration, LogLevel } from "@azure/msal-browser";

export const AUTH: Configuration = {
    auth: {
        authority: "https://login.microsoftonline.com/caffa6a1-5f15-46ba-a25e-9c6b54875af2",
        clientId: "5abb7891-f663-40f6-9ccc-ceb76d1728ce",
        redirectUri: window.location.origin,
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            }
        }
    }
};