import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, Collapse, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Skeleton, Toolbar } from "@mui/material";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MENU, MenuItem } from "../config/Menu";

const drawerWidth = 256

type NavigationProps = {
    mobileOpen: boolean
    handleDrawerToggle: () => void
}
export const Navigation = ({ mobileOpen, handleDrawerToggle }: NavigationProps) => {

    const navigate = useNavigate()
    const location = useLocation()
    const [open, setOpen] = useState(false);
    const [isNestedMenuActive, setIsNestedMenuActive] = useState<number | undefined>(-1);
    const [isMainMenuActive, setIsMainMenuActive] = useState<number>(-1);

    const isLoading = false
    const isError = false

    const handleMenuItems = (item: MenuItem, index: number, nestedIndex?: number) => {
        item.path && navigate(item.path); 
        nestedIndex === 2 ? setOpen(!open) : setOpen(false); 
        setIsMainMenuActive(index);  
        setIsNestedMenuActive(nestedIndex);
    }

    const renderCategory = (item: MenuItem, mainIndex: number) => {
        return (
            <div key={item.name}>
                <ListItem sx={{ py: 2, px: 3, fontSize: 16, color: 'common.black', fontWeight: 500 }}>
                    {item.name}
                </ListItem>
                <Box>
                    {item.items && item.items.map((item, index) => renderItem(item, mainIndex, index))}
                </Box>
            </div>
        )
    }

    const renderItem = (item: MenuItem, mainItemIndex: number, nestedItemIndex?: number) => {
        return (
            <>
                <ListItem disablePadding key={item.name}>
                    <ListItemButton selected={isNestedMenuActive === nestedItemIndex && isMainMenuActive === mainItemIndex} sx={{ py: '4px', px: 3 }} onClick={() => handleMenuItems(item, mainItemIndex, nestedItemIndex)}>
                        {isNestedMenuActive === nestedItemIndex && isMainMenuActive === mainItemIndex && <div style={{ height: 22, background: 'linear-gradient(180deg, #B84787 0%, #81629D 100%)', borderRadius: '90px', position: 'relative', left: -16, marginRight: -4, padding: 2 }} />}
                        <ListItemIcon>{item.icon && React.createElement(item.icon)}</ListItemIcon>
                        <ListItemText>{item.name}</ListItemText>
                        {nestedItemIndex === 2 ? open ? <ExpandLess /> : <ExpandMore /> : null}   
                    </ListItemButton>
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                {   
                    item.items?.map((nestedItem) => {
                        const isSubActive = nestedItem.path === location.pathname
                        return <ListItem disablePadding key={nestedItem.name}>
                            <ListItemButton selected={isSubActive} sx={{ px: 3 }} onClick={()=> {nestedItem.path && navigate(nestedItem.path); }}>
                                <ListItemText>{nestedItem.name}</ListItemText>
                            </ListItemButton>
                        </ListItem>
                    })
                }
                </Collapse>
            </>
        )
    }

    const loadingItems = () => {
        return (
            <div>
                <ListItem>
                    <Skeleton width='100%' />
                </ListItem>
                <ListItem>
                    <Skeleton width='100%' />
                </ListItem>
                <ListItem>
                    <Skeleton width='100%' />
                </ListItem>
            </div>
        )
    }

    const drawer = (
        <div>
            <Toolbar sx={{ py: 5 }} />
            <List disablePadding>
                {isLoading || isError ? loadingItems() : MENU.map((item, index) => {
                    if (item.items) {
                        return renderCategory(item, index)
                    }
                    else {
                        return renderItem(item, index)
                    }
                })}
            </List>
        </div>
    );

    return (
        <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}>
            <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                PaperProps={{
                    style: {
                        boxSizing: 'border-box',
                        width: drawerWidth
                    }
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                }}
            >
                {drawer}
            </Drawer>
            <Drawer
                variant="permanent"
                PaperProps={{
                    style: {
                        boxSizing: 'border-box',
                        width: drawerWidth
                    }
                }}
                sx={{
                    display: { xs: 'none', sm: 'block' },
                }}
                open
            >
                {drawer}
            </Drawer>
        </Box >
    )
}