import { Cancel, CheckCircle } from "@mui/icons-material";
import { Box, Chip, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter, GridValueGetterParams } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { fetchBatchJobDetails } from "../../network/apiCalls";
import { getEnv } from "../../store/storeSelectors";
import { LoadingPage } from "../LoadingPage";

const showHealthStatus = (status: string) => {
  const healthStatus: any = {
    Enabled: <CheckCircle color="success" />,
    Disabled: <Cancel color="error" />,
  };
  return healthStatus[status]
};


const showTiming = (value: string) => {
  const time: any = {
    'Day': 'Daily',
    'Hour': 'Hourly',
    'Month': 'Monthly'
  }
  return time[value]
}

const highlightNonExistingBatchJob = (params: any) => {
  return <span style={{backgroundColor: params.row.notExistsInMainList ? 'yellow': 'white' }}>{params.value}</span>
}

export const BatchJobs = () => {

    const { state } = useLocation();
    const { stateCode } = state;
    const selectedEnv = useSelector(getEnv);

    const { isLoading, isError, data, refetch, isFetching } = useQuery({
      queryKey: ["masterlist"],
      queryFn: () => fetchBatchJobDetails(selectedEnv, stateCode)
    });  

    const enabledJobs = data && data?.filter((item: any) => item.state === 'Enabled')
    const disabledJobs = data && data?.filter((item: any) => item.state !== 'Enabled')


    const columns: GridColDef[] = [
      {
        field: "batchJobName",
        headerName: "BatchJob Name",
        disableColumnMenu: true,
        minWidth: 400,
        headerClassName: "super-app-theme--header",
        renderCell: (params) => {
          return highlightNonExistingBatchJob(params)
        }
      },
      {
        field: "state",
        headerName: "State",
        align: "center",
        headerAlign: "center",
        width: 150,
        headerClassName: "super-app-theme--header",
        disableColumnMenu: true,
        renderCell: (params) => {
          return showHealthStatus(params?.value)
        }
      },
      {
        field: "batchJobStartTime",
        headerName: "Execution Time",
        headerClassName: "super-app-theme--header",
        width: 200,
        headerAlign: "center",
        align: "center",
        disableColumnMenu: true,
        valueGetter: (params: GridValueGetterParams<any, string>) => {
          return params?.value && new Date(params.value).toLocaleTimeString()
        },
      },
      {
        field: "frequency",
        headerName: "Frequency",
        width: 130,
        headerClassName: "super-app-theme--header",
        disableColumnMenu: true,
        valueGetter: (params: GridValueGetterParams<any, string>) => {
          return params.value && showTiming(params.value);
        },
      },
      {
        field: "interval",
        headerName: "Interval",
        width: 80,
        headerAlign: "center",
        align: "center",
        headerClassName: "super-app-theme--header",
        disableColumnMenu: true,
      },
      {
        field: "executionStatus",
        headerName: "Execution Status",
        width: 200,
        headerClassName: "super-app-theme--header",
        sortable: true,
        disableColumnMenu: true,
      },
    ];
    
    function CustomToolbar() {
      return (
        <GridToolbarContainer>
          <GridToolbarExport />
          <GridToolbarQuickFilter />
          <Box style={{ flex: 0.8 }} >
          <span style={{display: 'flex', flex: 1, justifyContent: 'end'}}>
            <Chip icon={<CheckCircle color="success"/>} label="Enabled" />
            <Chip icon={<Cancel color="error" />} label="Disabled" sx={{mx:1}}/>
          </span>
          </Box>
        </GridToolbarContainer>
      );
    }

    return (
        <Box>
           
          <Typography variant="h6" component="h1" pb={2}>
            {`Selected State: ${stateCode},  Selected Env: ${selectedEnv}`}
          </Typography>

        <Box  pb={2}>
        <Typography variant="h6" component="h1">
          Enabled/Disabled Jobs Count
        </Typography>
        <Table sx={{width: '45%', my: 2}} >
          <TableHead sx={{ bgcolor: "#4A2E62" }}>
            <TableRow>
              <TableCell align='center' style={{ color: "white" }}>Enabled</TableCell>
              <TableCell align='center' style={{ color: "white" }}>Disabled</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              enabledJobs && enabledJobs?.length &&
                 <TableRow>
                  <TableCell align='center'>{isLoading ? <Skeleton /> : enabledJobs?.length}</TableCell>
                  <TableCell align='center'>{isLoading ? <Skeleton /> : disabledJobs?.length}</TableCell>
                </TableRow>
            }
          </TableBody>
        </Table>
        </Box>
        <Typography variant="h6" component="h1" pb={2}>
          Batch Jobs Execution Status
        </Typography>
        {
          isLoading ? <LoadingPage /> : 
          <DataGrid
            density="compact"
            autoHeight
            rows={data}
            loading={isLoading || isFetching}
            columns={columns}
            getRowId={(row: any) => {
              return row.batchJobName;
            }}
            disableRowSelectionOnClick
            sx={{
              fontSize: 12,
              maxWidth: '90%',
              "& .super-app-theme--header": {
                backgroundColor: "#4A2E62",
                color: "white",
              },
              "& .MuiDataGrid-sortIcon": {
                opacity: 1,
                color: "white",
              },
            }}
            pagination
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            slots={{ toolbar: CustomToolbar }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 15,
                },
              },
            }}
            pageSizeOptions={[5, 10, 15, 20, 50]}
          />
        }
        </Box>
    )
}