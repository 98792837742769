import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    issuance: [],
    redemption: [],
    filteredIssuance: [],
    filteredRedemption: [],
    health: [],
    isLoading: false,
    ebtTab: 0,
    ebtStateCode: '',
}

const ebtSlice = createSlice({
    name: 'ebt',
    initialState,
    reducers: {
        setIssuance: (state, action) => {
            state.issuance = action.payload
        },
        setRedemption: (state, action) => {
            state.redemption = action.payload
        },
        setFilteredIssuance: (state, action) => {
            state.filteredIssuance = action.payload
        },
        setFilteredRedemption: (state, action) => {
            state.filteredRedemption = action.payload
        },
        setEBTHealth: (state, action) => {
            state.health = action.payload
        },
        setEBTLoader: (state, action) => {
            state.isLoading = action.payload
        },
        setEBTTabIndex: (state, action) => {
            state.ebtTab = action.payload
        },
        setEBTStateCode: (state, action) => {
            state.ebtStateCode = action.payload
        }
    }
})

export const { setIssuance, setRedemption, setEBTHealth, setEBTLoader, 
    setEBTTabIndex, setEBTStateCode, setFilteredIssuance, setFilteredRedemption } = ebtSlice.actions;

export default ebtSlice.reducer;
