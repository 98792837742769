import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Dayjs } from 'dayjs';

interface dateProps {
    label: string,
    value: Dayjs | null,
    onChange: (value: Dayjs | null ) => void,
    defaultValue?: Dayjs,
    minDate?: Dayjs
    maxDate?: Dayjs
}

export const Calendar = ({label, value, onChange, minDate, maxDate, defaultValue}: dateProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']}>
        <DateTimePicker label={label} defaultValue={defaultValue} value={value} onChange={onChange} minDate={minDate} maxDate={maxDate}/>
      </DemoContainer>
    </LocalizationProvider>
  );
}