import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  batchJobs: [],
  graphData: [],
  isLoading: false,
  graphLoader: false,
  filterBatchJobs : [],
  filteredGraphData: []
}

const batchJobsSlice = createSlice({
    name: 'batchJobs',
    initialState,
    reducers: {
        setBatchJobs: (state, action)=> {
            state.batchJobs = action.payload
        },
        setGraphData: (state, action)=> {
            state.graphData = action.payload
        },
        setLoader: (state, action)=> {
            state.isLoading = action.payload
        }, 
        setGraphLoader: (state, action)=> {
            state.graphLoader = action.payload
        }, 
        setFilteredBatchJobs: (state, action)=> {
            state.filterBatchJobs = action.payload
        },
        setFilteredGraphData: (state, action)=> {
            state.filteredGraphData = action.payload
        }
    }
})

export const {setBatchJobs, setGraphData, 
    setLoader, setGraphLoader, 
    setFilteredBatchJobs, setFilteredGraphData} = batchJobsSlice.actions;

export default batchJobsSlice.reducer;
