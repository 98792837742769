import React from "react"
import { createRoot } from "react-dom/client"
import { PublicClientApplication } from "@azure/msal-browser"
import { MsalProvider } from "@azure/msal-react"

import { App } from "./App"
import { AUTH } from "./config/Authentication"

const container = document.getElementById("root")
const root = createRoot(container!)

const instance = new PublicClientApplication(AUTH)

root.render(
    <React.StrictMode>
        <MsalProvider instance={instance}>
            <App />
        </MsalProvider>
    </React.StrictMode>
);