import { Cancel, CheckCircle } from "@mui/icons-material";
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { getGraphData } from "../store/storeSelectors";

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const BatchJobsTable: React.FC = () => {
  const batchJobs = useSelector(getGraphData)
  const output = batchJobs?.reduce((acc: any, item: any) => {
    const date = item.fullDate;
    if (!acc[date]) {
      acc[date] = []
    }
    acc[date].push({
      stateCode: item.stateCode,
      successCount: item.successCount,
      failureCount: item.failureCount,
    });

    return acc
  }, {})

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const getDate = (date: string): string => {
    const newDate = new Date(date);
    const formattedDate = `${monthNames[newDate.getMonth()]} ${newDate.getDate()}`;
    return formattedDate;
  };

  const renderStatusCell = (successCount: number, failureCount: number) => {
    if (successCount && failureCount) {
      return <div>
        <div><CheckCircle color="success" /><Cancel color="error" /></div>
        <div>{successCount} / {failureCount}</div>
      </div>
    } else if (successCount > 0 && !failureCount) {
      return <div>
        <div><CheckCircle color="success" /></div>
        <div>{successCount}</div>
      </div>
    } else if (!successCount && failureCount > 0) {
      return <div>
        <div><Cancel color="error" /></div>
        <div>{failureCount}</div>
      </div>
    } else return <></>
  };

  return (
    <Box sx={{ maxWidth: 800 }}>
      <Box border={1} sx={{ borderColor: "grey.300" }}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead sx={{ bgcolor: "#4A2E62" }}>
              <TableRow>
                <TableCell align="center" sx={{ color: "white" }}>StateCode</TableCell>
                {Object.keys(output).map(date => (
                  <TableCell key={date} align="center" sx={{ color: "white" }}>{getDate(date)}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(output).reduce((rows: any, item: any) => {
                output[item].forEach((entry: { stateCode: any; successCount: any; failureCount: any; }) => {
                  const existingRow: any = rows.find((row: { [x: string]: any; }) => row['stateCode'] === entry.stateCode);
                  if (existingRow) {
                    existingRow[item] = renderStatusCell(entry.successCount, entry.failureCount);
                  } else {
                    const newRow: any = {
                      stateCode: entry.stateCode,
                      [item]: renderStatusCell(entry.successCount, entry.failureCount)
                    };
                    rows.push(newRow);
                  }
                });
                return rows;
              }, []).map((row: any) => {
                return (
                  <StyledTableRow key={row.stateCode}>
                    <TableCell align="center">{row.stateCode}</TableCell>
                    {Object.keys(output).map(date => (
                      <TableCell key={`${date}-${row.stateCode}`} align="center">
                        {row[date] || '---'}
                      </TableCell>
                    ))}
                  </StyledTableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  )
}