import { Box } from '@mui/material';
import React from 'react';
import { Pie, PieChart, Tooltip } from 'recharts';

type exceptions = {
  name: string,
  value: number
}

export type progressCount = {
    chart: {exceptionDetails: any[], failCount: any[]}
    updateTable: any
}


const COLORS = ['#0088FE', '#00C49F', ];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export const EBTIssuanceChart: React.FC<progressCount> = (props) => {
    const {chart, updateTable} = props;
    let pieData: Array<exceptions> = [];
    chart?.failCount.forEach((item: any)  => {
      pieData.push({
        name: item['exceptionType'],
        value: item['count']
      })
    });

    const renderTable = (props: any) => {
      pieData.push({
        name: props.name,
        value: props.value
      })
      const exceptions = chart?.exceptionDetails.filter(item => item.exceptionType === props.name)
    }

    return ( 
      <Box>
        <PieChart width={500} height={400}>
          {
            pieData.map((item,index )=> {
              return <Pie 
              dataKey={'value'}
              isAnimationActive={true}
              data={pieData}
              cx={200}
              cy={200}
              fill="#8884d8"
              outerRadius={100}
              label
              onClick={renderTable}
            >
            </Pie>
            }) 
          }
           <Tooltip />
        </PieChart>
      </Box>
    )
}
