import { Cancel, CheckCircle, FilterList } from "@mui/icons-material";
import { Backdrop, Box, Button, Menu, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { DataGrid, GridCallbackDetails, GridColDef, GridComparatorFn, GridRenderCellParams, GridRowParams, GridToolbarContainer, GridToolbarQuickFilter, GridValueGetterParams, MuiEvent, gridNumberComparator } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../config/Routes";
import { fetchMyWICHealth, fetchMyWICMonitoring } from "../../network/apiCalls";
import { setMyWicHealth, setMyWicService } from "../../store/myWicSlice";
import { getEnv, getMyWicHealth, getMyWicLoadingStatus, getMyWicServices } from "../../store/storeSelectors";
import { LoadingPage } from "../LoadingPage";


const convertMsToSec = (time: number): string => {
  if(time > 1000){
    return `${(time/1000).toFixed(1)} s`;
  }
  return `${time.toFixed(1)} ms`;
}


const columns: GridColDef[] = [
    {
      field: "operationName",
      headerName: "Operation Name",
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderCell: (params: GridRenderCellParams<any, string>) => {
        return <Tooltip title={params?.value}><p>{params?.value}</p></Tooltip>
      },
    },
    {
      field: "duration",
      headerName: "Duration",
      align: "center",
      headerAlign: "center",
      minWidth: 120,
      headerClassName: "super-app-theme--header",
      disableColumnMenu: true,
      sortable: true,
      valueGetter: (params: GridValueGetterParams<any, number>) => {
        return params?.value && convertMsToSec(params?.value);
      },
    },
    {
      field: "total",
      headerName: "Total Count",
      align: "center",
      headerAlign: "center",
      minWidth: 120,
      headerClassName: "super-app-theme--header",
      disableColumnMenu: true,
      sortable: true,
    },
    {
      field: "failPercentage",
      headerName: "Failure %",
      align: "center",
      headerAlign: "center",
      minWidth: 120,
      headerClassName: "super-app-theme--header",
      disableColumnMenu: true,
      sortable: true,
      valueGetter: (params: GridValueGetterParams<any, number>) => {
        return params.value ? `${(params.value * 100).toFixed(1)} %` : `0 %`;
      },
    },
    {
      field: "failureCount",
      headerName: "Failure Count",
      align: "center",
      headerAlign: "center",
      minWidth: 120,
      headerClassName: "super-app-theme--header",
      disableColumnMenu: true,
      sortable: true,
    },
    {
      field: "successPercentage",
      headerName: "Success %",
      minWidth: 120,
      headerClassName: "super-app-theme--header",
      disableColumnMenu: true,
      align: "center",
      headerAlign: "center",
      sortable: true,
      valueGetter: (params: GridValueGetterParams<any, number>) => {
        return params.value ? `${(params.value * 100).toFixed(1)} %` : `0 %`;
      },
    },
    {
      field: "successCount",
      headerName: "Success Count",
      minWidth: 120,
      headerClassName: "super-app-theme--header",
      disableColumnMenu: true,
      align: "center",
      headerAlign: "center",
      sortable: true,
    },
  ];

const days = ['Daily','Weekly','Monthly'];

export const MyWICMonitoringPage = () => {
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] =  useState('');
  const selectedEnv = useSelector(getEnv);
  const dispatch = useDispatch();
  const healthData = useSelector(getMyWicHealth);
  const services = useSelector(getMyWicServices);
  const showLoader = useSelector(getMyWicLoadingStatus)


    const open = Boolean(anchorEl);

    const myWicHealthQuery = useQuery({
        queryKey: ["health"],
        queryFn: () => fetchMyWICHealth(selectedEnv),
    });

    const {data, isLoading, isError} = useQuery({
        queryKey: ["monitoring"],
        queryFn: () => fetchMyWICMonitoring(selectedEnv, true, dispatch, '', ''),
    });
    const myWicHealthData = myWicHealthQuery?.data;

    useEffect(() => {
      dispatch(setMyWicService(data))
      dispatch(setMyWicHealth(myWicHealthData))
    },[data, dispatch, myWicHealthData])

    const navigate = useNavigate();


    const showHealthStatus = (status: string) => {
        const healthStatus: any = {
          Active: <CheckCircle color="success" />,
          Stopped: <Cancel color="error" />,
          Running: <CheckCircle color="success" />,
        };
        return healthStatus[status]
    };

    const onRowItemClick = (
        params: GridRowParams,
        event: MuiEvent,
        details: GridCallbackDetails
      ) => {
        navigate(
          ROUTES.monitoringMyWicExceptions.path,
          {
            state: { operationName: params.row.operationName, startDate: startDate, endDate: endDate },
          }
        );
    }

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleMenuItemClick = (event: any,
      index: number) => {
      setSelectedIndex(index);
      setAnchorEl(null);
      switch(event.target.textContent){
        case 'Weekly':
          getTiming(6)
          break;
        case 'Monthly':
          getTiming(29)
          break;
        default:
          getTiming(1)
          break;
      }
    }
  
    const clearTimeFilter = () => {
      setSelectedIndex(-1);
      setAnchorEl(null);
      getTiming(1);
    }

    const getTiming = async (day: number) => {
      var today = new Date();
      var daysAgo = new Date(today);
      daysAgo.setDate(today.getDate() - day)
      const date =  `${daysAgo.getMonth()+1}/${daysAgo.getDate()}/${daysAgo.getFullYear()}`
      const now =  `${today.getMonth()+1}/${today.getDate()}/${today.getFullYear()}`
      const filteredData = await fetchMyWICMonitoring(selectedEnv, true, dispatch, date, now);
      dispatch(setMyWicService(filteredData))
      setEndDate(now);
      setStartDate(date);
    }

    function CustomToolbar() {
      return (
        <GridToolbarContainer>
          <Box style={{ flex: 1 }}></Box>
          <GridToolbarQuickFilter />
        </GridToolbarContainer>
      );
    }

    return <Box>
      {
        showLoader && <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={showLoader}
        >
          <LoadingPage />
        </Backdrop>
      }
        <Typography variant="h6" component="h1" pb={2}>myWIC Process</Typography>
        {
            healthData?.length && <Box>
            <Typography variant="h6" component="h1">myWIC Health</Typography>
            <Table sx={{width: 600, my: 2}} >
                <TableHead sx={{ bgcolor: "#4A2E62" }}>
                    <TableRow>
                    <TableCell align='left' style={{ color: "white" }}>Service Name</TableCell>
                    <TableCell align='left' style={{ color: "white" }}>Region</TableCell>
                    <TableCell align='center' style={{ color: "white" }}>Health</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {healthData?.map(( item: any, index: number) => (
                    <TableRow key={index}>
                        <TableCell align='left'>{item.serviceName}</TableCell>
                        <TableCell align='left'>{item.regionName}</TableCell>
                        <TableCell align='center'><Tooltip title={item.health}>{showHealthStatus(item.health)}</Tooltip></TableCell>
                    </TableRow>
                    ))}
                </TableBody>
            </Table>
            </Box>
        }

        {services?.length > 0 ?  <Box>
        <Typography variant="h6" component="h2" py={2}>
            myWIC Monitoring
        </Typography>
        <Box sx={{ border: "1px solid #ccc" }}>
            <Button
              id="demo-positioned-button"
              aria-controls={open ? 'demo-positioned-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              startIcon={<FilterList />}
            >
              Time filter
            </Button>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              {
                days?.map((item: string, index: number) => (
                  <MenuItem
                    selected={index === selectedIndex}
                    onClick={(event) => handleMenuItemClick(event, index)}
                    sx={{ color: index === selectedIndex ? '#4A2E62' : '#000' }}
                    key={index}
                  >
                    {item}
                  </MenuItem>
                ))
              }
              <Box sx={{ justifyContent: "center", display: "flex", mt: 1 }}>
                <Button
                  sx={{ m: 'auto' }}
                  variant="contained"
                  size="small"
                  onClick={clearTimeFilter}
                >
                  Clear
                </Button>
              </Box>
            </Menu>
          </Box>
        <DataGrid
            density="standard"
            autoHeight
            rows={isLoading ? [] : services}
            loading={isLoading}
            columns={columns}
            getRowId={(row) => row.operationName}
            sx={{
              fontSize: 12,
              "& .super-app-theme--header": {
                backgroundColor: "#4A2E62",
                color: "white",
              },
              "& .MuiDataGrid-sortIcon": {
                opacity: 1,
                color: "white",
              },
            }}
            slots={{
              toolbar: CustomToolbar,
            }}
            
            pagination
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            disableRowSelectionOnClick
            onRowClick={onRowItemClick}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
              sorting: {
                sortModel: [{field: 'failureCount', sort: 'desc'}]
              }
            }}
            pageSizeOptions={[5, 10, 15, 20, 50]}
          />   
          </Box>

          : <LoadingPage />
        }
    </Box>
}