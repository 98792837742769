import { Box, Typography } from "@mui/material"
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar, Rectangle } from "recharts"

export const CrmExceptionsChart = ( {data, label, xAxisKey, dataKey}: any) => {
    return <Box>
    <Typography variant="h6" component="h1" py={2} textAlign={'center'}>
        {label}
    </Typography>
    <BarChart width={700}
        height={300}
        data={data}
        maxBarSize={40}
        margin={{
            top: 5,
            right: 30,
            bottom: 5,
        }}>

        <CartesianGrid strokeDasharray="3 3" />

        <XAxis dataKey={xAxisKey} />
        <YAxis />
        <Tooltip />
        <Bar
            dataKey={dataKey}
            fill="#2d607f"
            activeBar={<Rectangle fill=" #357197" />}
        />
    </BarChart>
</Box>
}